const continentCountryMap: Map<string, string[]> = new Map([
    ["AFR", ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CD", "CG", "CI", "DJ", "EG", "ER", "SZ", "ET", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RE", "RW", "ST", "SN", "SC", "SL", "SO", "ZA", "SS", "SD", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW"]],
    ["ASI", ["AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "CY", "GE", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KW", "KG", "LA", "LB", "MY", "MV", "MN", "MM", "NP", "KP", "OM", "PK", "PW", "PH", "QA", "SA", "SG", "KR", "LK", "SY", "TW", "TJ", "TH", "TR", "TM", "AE", "UZ", "VN", "YE"]],
    ["EUR", ["AD", "AL", "AT", "BY", "BE", "BA", "BG", "HR", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IS", "IE", "IT", "XK", "LV", "LI", "LT", "LU", "MK", "MT", "MD", "MC", "ME", "NL", "NO", "PL", "PT", "RO", "RU", "SM", "RS", "SK", "SI", "ES", "SE", "CH", "UA", "GB", "VA"]],
    ["NAM", ["AG", "BS", "BB", "BZ", "CA", "CR", "CU", "DM", "DO", "SV", "GD", "GT", "HT", "HN", "JM", "MX", "NI", "PA", "KN", "LC", "VC", "TT", "US"]],
    ["SAM", ["AR", "BO", "BR", "CL", "CO", "EC", "GY", "PY", "PE", "SR", "UY", "VE"]],
    ["OCE", ["AS", "AU", "CK", "TL", "FJ", "PF", "GU", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "MP", "PG", "PN", "WS", "SB", "TK", "TO", "TV", "VU", "WF"]],
]);

const statesMap = new Map<string, string[]>([
    ['OCE:AU', ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT', 'NT']],
    ['SAM:BR', ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO',]],
    ['NAM:CA', ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT']],
    ['ASI:MM', ['CH', 'KA', 'KY', 'KN', 'MO', 'RA', 'SH']],
    ['AFR:ET', ['AA', 'AF', 'AM', 'BG', 'DD', 'GA', 'HR', 'OR', 'SO', 'SN', 'TI']],
    ['EUR:DE', ['BW', 'BY', 'BE', 'BB', 'HB', 'HH', 'HE', 'NI', 'MV', 'NW', 'RP', 'SL', 'SN', 'ST', 'SH', 'TH']],
    ['ASI:IN', ['AP', 'AR', 'AS', 'BR', 'CT', 'GA', 'GJ', 'HR', 'HP', 'JH', 'KA', 'KL', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OR', 'PB', 'RJ', 'SK', 'TN', 'TG', 'TR', 'UP', 'UT', 'WB']],
    ['NAM:MX', ['AG', 'BC', 'BS', 'CM', 'CS', 'CH', 'CO', 'CL', 'DG', 'DF', 'GT', 'GR', 'HG', 'JA', 'MX', 'MI', 'MO', 'NA', 'NL', 'OA', 'PU', 'QE', 'QR', 'SL', 'SI', 'SO', 'TB', 'TM', 'TL', 'VE', 'YU', 'ZA']],
    ['OCE:FM', ['YAP', 'CHK', 'PNI', 'KSA']],
    ['AFR:NG', ['AB', 'AD', 'AK', 'AN', 'BA', 'BY', 'BE', 'BO', 'CR', 'DE', 'EB', 'ED', 'EK', 'EN', 'GO', 'IM', 'JI', 'KD', 'KN', 'KT', 'KE', 'KO', 'KW', 'LA', 'NA', 'NI', 'OG', 'ON', 'OS', 'OY', 'PL', 'RI', 'SO', 'TA', 'YO', 'ZA']],
    ['OCE:PW', ['AM', 'AR', 'AN', 'HA', 'KA', 'KO', 'ME', 'NA', 'NP', 'NR', 'NG', 'PE', 'SO']],
    ['AFR:SS', ['BN', 'BW', 'LK', 'WR', 'WE', 'CE', 'EE', 'JG', 'UN', 'UP', 'AB', 'PI', 'RU']],
    ['NAM:US', ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'DC', 'WV', 'WI', 'WY']],
]);

export class CountriesCodeHelper {
    static getContinentsMap(): Map<string, string[]> {
        return continentCountryMap;
    }

    static getStatesMap(country: string): Map<string, string[]> {
        const statesByCountry = statesMap.get(country) ?? [];

        return new Map([[country, statesByCountry]]);
    }
}
