import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { Observable, merge, iif, of, throwError, BehaviorSubject, filter, tap, switchMap, first, map, EMPTY, catchError, concatMap, delay } from "rxjs";

import { SystemsQuery } from "./systems.query";
import { SystemsStore } from "./systems.store";
import { SystemsIconsService } from './systems-icons.service';
import { ApiClientSystemsService } from "src/app/api/api-client-systems.service";
import { UnverifiedSystemsService } from './../../radar/state/unverified-systems.service';
import { DataSourceOriginEnum, EditResult, ReviewStatus, SuggestedStateEnum, SystemState } from "src/app/api/models/systems/systems.enum";
import { DataTypeSuggetionsUpdateData, AddCatalogSystemResponse, IntegrationSystemTestResponse, ProcessingActiviySuggetionsUpdateData, EnabledRequestActions, SystemAiData, UpdateIconUrlPayload, IconSignedUrlResponse, IconSignedUrlPayload, SystemsIconsV2Payload, SystemsIconsV2Response, CustomSystemIcon, CatalogSystemIcon, BatchDataType } from './../../api/models/systems/systems.interface';
import { CompanySystemsResponse, ExtendedDataType, IntegrationCatalogType, IntegrationField, IntegrationSystem, PublicSystem, SystemGeneralData, SystemReviewStatus, SystemsIconsResponse } from "src/app/api/models/systems/systems.interface";
import { ApiKeyResponse, InetgrationsDeletionResponse, IntegrationConfig, IntegrationPreviewResponse, IntegrationTest, InternalSystemTestResponse } from "src/app/api/models/integrations/integrations.interface";
import { IntegrationPreviewDialogSource } from "src/app/requests/request-ticket-v2/integration-indications/integrations-preview-dialog/integration-preview-dialog-data.enum";
import { ProcessingActivity, ProcessingActivityPartial } from "src/app/api/models/processing-activities/processing-activities.interface";
import { IntegrationInstanceType, IntegrationType } from "src/app/api/models/integrations/integration-type.enum";
import { LoggerService } from "src/app/logger/logger.service";
import { ProcessingActivitiesQuery } from "src/app/processing-activities/state/processing-activities.query";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { UserSearchService } from "src/app/user-search/user-search.service";
import { SystemInstance, UpdateSystemCategories } from "../models/systems.interface";
import { SystemsDirectoryQuery } from "src/app/systems-directory/state/systems-directory.query";
import { TestInternalSystem } from "../system-page/system-request-handling/integration-email/test-internal-system.interface";
import { OauthAuthorizationState } from "../system-page/system-request-handling/models/oauth-authorization-state.interface";
import { FeedbackTypeEnum, FeedbackStateEnum } from 'src/app/api/models/feedback/feedback.enum';
import { Feedback, FeedbackRequest } from 'src/app/api/models/feedback/feedback.interface';
import { ApiClientFeedbackService } from 'src/app/api/api-client-feedback.service';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { TicketType } from "src/app/api/models/requests/ticket-type.enum";
import { DataSource } from "src/app/requests/request-ticket-v2/ticket-steps/ticket-steps-processing/data-source.inteface";
import { FeatureFlags } from "src/app/api/models/profile/profile-feature-flags.enum";
import { FeatureFlagQuery } from "src/app/feature-flag/state/feature-flag.query";
import { FileUploadService } from "src/app/services/file-upload.service";
import { SpecialSystemType } from "../models/custom-system-type.enum";

@Injectable({
	providedIn: 'root',
})
export class SystemsService {
    private readonly loggerName: string = 'SystemsService';

    readonly MineCustomSystemType = SpecialSystemType.Custom;

    private integrationCatalogTypesMap: BehaviorSubject<Map<string, IntegrationCatalogType[]>> = new BehaviorSubject(new Map(null));
	integrationCatalogTypesMap$ = this.integrationCatalogTypesMap.asObservable();

    constructor(
        private apiClientSystems: ApiClientSystemsService,
        private systemsIconsService: SystemsIconsService,
        private userSearchService: UserSearchService,
        private systemsDirectoryQuery: SystemsDirectoryQuery,
        private systemsQuery: SystemsQuery,
        private systemsStore: SystemsStore,
        private logger: LoggerService,
        private contentPipe: ContentPipe,
        private processingActivitiesQuery: ProcessingActivitiesQuery,
        private unverifiedSystemsService: UnverifiedSystemsService,
        private apiClientFeedback: ApiClientFeedbackService,
        private featureFlagsQuery: FeatureFlagQuery,
        private fileService: FileUploadService
    ) { }

    init(): Observable<void> {
		this.logger.debug(this.loggerName, 'init()');
        return this.getSystemsFromServer();
	}

    getSystemsFromServer(): Observable<void> {
        return this.apiClientSystems.getCompanySystems().pipe(
            first(),
            map(response => this.getSystemUpdatedUsage(response)),
            switchMap(response => this.updateSystemsStore(response)),
            map(() => this.logger.info(this.loggerName, 'get systems from server'))
        )
    }

    private getSystemUpdatedUsage(response: CompanySystemsResponse): CompanySystemsResponse {
        response.results.map(system => { 
            if (!(system.dataSourceOrigin.find(d => d === DataSourceOriginEnum.Email))) {
                return {...system, systemUsage: {...system.systemUsage, discoveryScore: null}}
            }

            return system;
        })
        return response;
    }

    private getSystemInstance(systemId: string, iconPath: string): Observable<SystemInstance> {
        return this.apiClientSystems.getSystem(systemId).pipe(
            first(),
            tap(response => {
                this.systemsStore.update(systemId, (entity) => { 
                    entity.deep = true;
                    entity.iconPath = iconPath;
                });
                response.integrationInstances?.forEach(instance => this.updateInstanceInStore(instance));
            }),
            switchMap(() => this.systemsQuery.selectEntity(systemId)),
            tap(() => this.logger.info(this.loggerName, `get system by id: ${systemId} from server`)),
        )
    }

    private updateSystemsStore(response: CompanySystemsResponse): Observable<void> {
        if (response?.results?.length) {
            return (this.featureFlagsQuery.getFlag(FeatureFlags.DevCustomSystemEdit)) ? this.getSystemsIconsV2(response) : this.getSystemsIcons(response);
        }

        return of(this.systemsStore.set([]));
    }

    private getSystemsIcons(response: CompanySystemsResponse): Observable<void> {
        const systemTypes = Array.from(new Set(response.results?.map(system => system.systemType))).filter(type => type !== this.MineCustomSystemType);
        if (systemTypes.length) {
            return this.systemsIconsService.getSystemsIcons(systemTypes).pipe(
                tap(res => this.initStore(response, res)),
                map(() => this.logger.info(this.loggerName, 'get systems icons from server'))
            );
        }
        return of(this.initStore(response, {icons: []} as SystemsIconsResponse));
    }

    private getSystemsIconsV2(systemsResponse: CompanySystemsResponse): Observable<void> {
        const payload: SystemsIconsV2Payload = { systems: [] };
        systemsResponse.results.forEach(system => payload.systems.push(this.getSystemIconObject(system.systemType, system.systemId)));
        return this.systemsIconsService.getSystemsIconsV2(payload).pipe(
                map(iconsResponse => this.initStoreV2(systemsResponse, iconsResponse)),
                map(() => this.logger.info(this.loggerName, 'Set icons V2 to systems store'))
            );
    }

    private getSystemIconObject(type:string, id: string): CatalogSystemIcon | CustomSystemIcon {
        return type === this.MineCustomSystemType ? ({ systemId: id } as CustomSystemIcon) : ({ systemType: type } as CatalogSystemIcon);
    }

    private initStore(response: CompanySystemsResponse, res: SystemsIconsResponse): void {
        this.systemsStore.set(response.results.map(system => {
            return {
                ...system,
                iconPath: res.icons[system.systemType]
            } as SystemInstance;
        }));
    }

    private initStoreV2(systems: CompanySystemsResponse, icons: SystemsIconsV2Response): void {
        this.systemsStore.set(systems.results.map(system => {
            return {
                ...system,
                iconPath: system.systemType === this.MineCustomSystemType ? icons.customSystemIcons[system.systemId] : icons.iconsByService[system.systemType]
            } as SystemInstance;
        }))
    }

    getSystemInstanceByPath(path: string, shallow: boolean = true): Observable<SystemInstance> {
        if (!this.systemsQuery.hasEntity(({ pathFriendlyId }) => pathFriendlyId === path)) {
            throwError({ message: `Can't find system for path: ${path}` });
        }
        return this.systemsQuery.selectEntity(({ pathFriendlyId }) => pathFriendlyId === path).pipe(
            first(),
            switchMap(system => this.getSystemInstance(system.systemId, system.iconPath)),
        );
    }

    getSystemInstanceById(systemId: string): Observable<SystemInstance> {
        return this.systemsQuery.selectEntity(systemId).pipe(
            switchMap(system => 
                iif(() => !system,
                    this.getSystemInstanceForTasks(systemId), 
                    of(system)
            )),
        );
    }

    private getSystemInstanceForTasks(systemId: string): Observable<SystemInstance> {
        return this.apiClientSystems.getSystem(systemId).pipe(
            first(),
            tap(response => this.systemsStore.add({...response, deep: true})),
            switchMap(() => this.systemsQuery.selectEntity(systemId)),
            tap(() => this.logger.info(this.loggerName, `get system by id: ${systemId} from server`)),
        )
    }

    selectSystemInstanceById(systemId: string): Observable<SystemInstance> {
        return this.systemsQuery.selectEntity(entity => entity.systemId === systemId).pipe(
            switchMap(response => iif(() => !!response,
                of(response),
                throwError({ message: `Can't find system by systemId: ${systemId}` })
            ))
        );
    }

    getIntergrationCatalogType(systemType: string): Observable<IntegrationCatalogType[]> {
        const type$ = this.integrationCatalogTypesMap$.pipe(
            map(response => response.get(systemType)));

		const set$ = type$.pipe(
            filter(response => !!response)
        );

        const notSet$ = type$.pipe(
            filter(response => !response),
            switchMap(() => this.getTypesServer(systemType)),
        );

        return merge(set$, notSet$);

    } 
    private getTypesServer(systemType: string): Observable<IntegrationCatalogType[]> {
        return this.apiClientSystems.getInegrationType(systemType).pipe(
            map(results => results.results),
            tap(results => this.updateIntegrationTypes(systemType, results))
        );
    }

    private updateIntegrationTypes(systemType: string, integrationTypes: IntegrationCatalogType[]): void {
        const integrationCatalogTypes = this.integrationCatalogTypesMap.getValue();
        this.integrationCatalogTypesMap.next(integrationCatalogTypes.set(systemType, integrationTypes));
    }

    editGeneral(systemId: string, systemData: SystemGeneralData): Observable<EditResult> {
        return this.apiClientSystems.editGeneralSystemInstance(systemId, systemData).pipe(
            switchMap(response => iif(() => response.result === EditResult.Success,
                                          this.updateGeneralInfoInStore(systemId, systemData).pipe(map(() => EditResult.Success)),
                                          of(response.result))
            )
        );
    }

    editOwner(systemId: string, owner: string): Observable<void> {
        return this.apiClientSystems.editOwner(systemId, owner).pipe(
            tap(() => this.updateOwnerInfoInStore(systemId, owner)),
        );   
    }

    private updateOwnerInfoInStore(systemId: string, owner: string): Observable<void> {
        this.systemsStore.update(systemId, { owner });
        return of(void 0);
    }

    private updateGeneralInfoInStore(systemId: string, systemData: SystemGeneralData): Observable<void> {
        this.systemsStore.update(systemId, entity => {
            return {
                ...entity,
                ...systemData,
            }
        });

        return of(void 0);
    }

    editIntegrationsInstance(integraitonInstance: IntegrationSystem): Observable<void> {
        return this.apiClientSystems.editIntegrationInstance(integraitonInstance).pipe(
            tap((res) => this.updateInstanceInStore(res)),
            map(() => this.logger.info(this.loggerName, 'updated integration instance'))
        )
    }

    testAndEditIntegrationsInstance(integraitonInstance: IntegrationSystem): Observable<ApiKeyResponse> {
        return this.apiClientSystems.testAndEditIntegrationInstance(integraitonInstance).pipe(
            tap(response => this.updateStoreIfSuccess(response)),
            map(response => response.testResult)
        );
    }

    private updateStoreIfSuccess(response: IntegrationSystemTestResponse): void {
        if (response.testResult.success) {
            this.updateInstanceInStore(response.instanceDto);
            this.logger.info(this.loggerName, 'updated integration instance');
        }
    }

    private updateInstanceInStore(integrationInstance: IntegrationSystem): void {
        const system = this.systemsQuery.getEntity(integrationInstance.systemId);
        const instanceIndex = system.integrationInstances.findIndex(i => i.integrationId === integrationInstance.integrationId);
        if (instanceIndex !== -1) { // instance exists already
            this.systemsStore.update(integrationInstance.systemId,(entity) => {entity.integrationInstances[instanceIndex] = integrationInstance});
        } else { // add new instance 
            this.systemsStore.update(integrationInstance.systemId, (entity) => {entity.integrationInstances.push(integrationInstance)})
        }
    }


    oauthConnect(systemId: string, id: string, integrationContent: IntegrationCatalogType, fields: IntegrationField): Observable<string> {
        const redirectUrl = `${window.location.protocol}//${window.location.host}/${RoutesManager.integrations}/${RoutesManager.oauth_callback}`;

		return this.apiClientSystems.oauthConnect(systemId, id, integrationContent.integrationType, fields, redirectUrl).pipe(
			map(res => res.url),
			tap(url => this.logger.info(this.loggerName, `received ${id} auth url: ${url}`))
		);
    }

    oauthCallback(paramMap: ParamMap): Observable<IntegrationConfig> {
        const redirectUrl = `${window.location.protocol}//${window.location.host}/${RoutesManager.integrations}/${RoutesManager.oauth_callback}`;
        const authState: OauthAuthorizationState = { code: paramMap.get('code'), state: paramMap.get('state'), redirectUrl };
        return this.apiClientSystems.oauthCallback(authState).pipe(
			tap((config: IntegrationConfig) => this.logger.info(this.loggerName, `${config.id} has been authenticated successfully`))
		);
	}

	oauthDisconnect(id: string): Observable<IntegrationConfig> {
        let integrationInstance = this.systemsQuery.getIntegrationInstanceById(id);

        return this.apiClientSystems.oauthDisconnect(id).pipe(
			tap(() => this.updateInstanceInStore({...integrationInstance, authenticated: false, enabled: false} as IntegrationSystem)),
			tap(() => this.logger.info(this.loggerName, `${id} has been disconnected`))
		);
    }

    //V2 for addSystem()
    addCatalogSystem(publicSystem: PublicSystem): Observable<AddCatalogSystemResponse> {
        let addCatalogSystemResponse: AddCatalogSystemResponse;

        return this.apiClientSystems.addCatalogSystem(publicSystem).pipe(
            tap(response => addCatalogSystemResponse = response),
            map(response => response?.system ? <SystemInstance>response.system : this.systemsQuery.getEntity(publicSystem.systemType)),
            concatMap(system => this.afterAddedSystem(system)),
            tap(system => this.handleAddSystemResponse(system, addCatalogSystemResponse.previousState)),
            map(() => addCatalogSystemResponse),
        );
    }

    updateSystem(systemId: string, system: Partial<SystemInstance>): Observable<void> {
        return this.apiClientSystems.updateSystem(systemId, system).pipe(
            tap(() => this.systemsStore.update(systemId, entity => { return {...entity, ...system}}))
        );
    }

    handleAddSystemResponse(system: SystemInstance, previousState?: SystemState): void {
        if (previousState !== SystemState.Inventory) {
            this.systemsStore.upsert(system.systemId, system); 
        }       
    }
    
    addSystem(publicSystem: PublicSystem, isInRadar: boolean = false): Observable<SystemInstance> {
        return this.apiClientSystems.addSystem(publicSystem).pipe(
            map(system => <SystemInstance>system),
            concatMap(system => this.afterAddedSystem(system)),
            tap(system => this.systemsStore.upsert(system.systemId, system)),
            tap(system => isInRadar && this.unverifiedSystemsService.removeUnverifiedSystemsFromStore([system.systemId]))
        );
    }
    
    private afterAddedSystem(system: SystemInstance): Observable<SystemInstance> {
        return this.systemsIconsService.getSystemsIcons([system.systemType]).pipe(
            map(res => {
                return {
                    ...system,
                    iconPath: res.icons[system.systemType]
                }
            })
        )
    }
    
    getNewIntegrationId(systemId: string): Observable<string> {
        return this.apiClientSystems.getIntegrationInstanceId(systemId).pipe(
            map(res => res.integrationId))
    }

    createIntegrationInstance(integrationId: string, systemId: string): Observable<void> {
        return this.editIntegrationsInstance(this.generateIntegrationInstance(integrationId, systemId))
    }

    private generateIntegrationInstance(integrationId: string, systemId: string): IntegrationSystem {
        const system = this.systemsQuery.getEntity(systemId);
        const instance: IntegrationSystem = {
            systemId,
            instanceType: IntegrationInstanceType.Manual,
            integrationId,
            integrationType: IntegrationInstanceType.Manual,
            enabled: true,
            customName: `${this.contentPipe.transform('systems.instance')} ${system.integrationInstances.length + 1}`,
            associatedPrivacyRights: []
        }

        return instance;
    }

    removeIntegrationInstance(systemId: string, integrationId: string, index: number): Observable<void> {
        return this.apiClientSystems.removeInstance(integrationId).pipe(
            tap(() => this.systemsStore.update(systemId, 
                (entity) => { 
                    if (index > -1) { 
                        entity.integrationInstances.splice(index, 1);
                    }
                }
            ))
        );
    }

    testInternalSystemIntegration(integration: TestInternalSystem): Observable<InternalSystemTestResponse> {
		return this.apiClientSystems.testInternalSystemIntegration(integration);
	}

    testCustomIntegration(integration: TestInternalSystem): Observable<InternalSystemTestResponse> {
		return this.apiClientSystems.testAutomatedCustomIntegration(integration);
	}

    testApiKeyIntegration(data: IntegrationTest): Observable<ApiKeyResponse> {
		return this.apiClientSystems.testApiKeyIntegration(data);
	}

    updateSystemUsageStore(systemId: string, score?: number): void {
        
        this.systemsStore.update(systemId, (entity) => { entity.systemUsage = {
            ...entity.systemUsage,
            score: score ?? entity.systemUsage.score,
            manuallySet: !!score ? true : entity.systemUsage.manuallySet,
        }});
    }

    updateAccountCount(systemId: string, addAccount: boolean): void {
        this.systemsStore.update(systemId, (entity) => { 
            entity.connectedAccounts = addAccount ? (entity.connectedAccounts ?? 0) + 1 : (entity.connectedAccounts ?? 0) - 1
        });
    }

    selectSystemsWithPaMap(): Observable<Map<string, ProcessingActivityPartial[]>> {
        return this.processingActivitiesQuery.selectAll()
            .pipe(
                map((processingActivities) => this.getSystemsWithPaMap(processingActivities)),
            )
    }

    private getSystemsWithPaMap(processingActivities: ProcessingActivity[]): Map<string, ProcessingActivityPartial[]> {
        const systemsWithPa = new Map<string, ProcessingActivityPartial[]>();
        
        processingActivities?.forEach(pa => {
            if (pa?.systems) {
                Object.values(pa.systems).forEach(s => {
                    const tempPaArray = systemsWithPa.get(s.id);
                    if (!!tempPaArray) {
                        const newArray = [...tempPaArray, pa];
                        systemsWithPa.set(s.id, <ProcessingActivityPartial[]>newArray);
                    }
                    else {
                        systemsWithPa.set(s.id, <ProcessingActivityPartial[]>[pa]);
                    }
                })
            }
        });

        return systemsWithPa;
    }

    getPasBySystem(systemId: string): ProcessingActivityPartial[] {
        const pa = [];
        const ropaPaItems = this.processingActivitiesQuery.getAll();
        ropaPaItems.forEach(r => {
            if (r.systems[systemId]) {
                pa.push(r);
            }
        });
        return pa;
    }

    validateFieldNotUsed(id: string): Observable<boolean> {
        return this.systemsQuery.selectAll().pipe(
            map(res => this.findFieldWithValue(res, id)),
        );
    }

    findFieldWithValue(res: SystemInstance[], id: string): boolean {
        let hasValue = false;
        res.forEach(r => {
            const field = r.customFields?.find(f => f.id === id)?.values;
            if (!!field?.text || !!field?.optionIds?.length) {
                hasValue = true;
                return;
            }
        });

        return hasValue;
    }

    changeReviewStatus(status: ReviewStatus, systemId: string): Observable<SystemReviewStatus> {
		return this.apiClientSystems.changeReviewStatus(status, systemId).pipe(
			tap((res) =>
				this.systemsStore.update(systemId, (entity) => {
					return {
						...entity,
						reviewStatus: res,
					};
				})
			)
		);
    }

    deleteTicketIntegrations(ticketId: string, integrations: string[]): Observable<InetgrationsDeletionResponse> {
		return this.apiClientSystems.deleteTicketIntegrations(ticketId, integrations);
	}

    getIntegrationPreviewUserSearch(integrationId: string, ticketId: string, source: IntegrationPreviewDialogSource): Observable<IntegrationPreviewResponse> {
		if (source === IntegrationPreviewDialogSource.UserSearch) {
			return this.userSearchService.previewUserData(integrationId, ticketId)
		}
	}

    markIntegrationAsDone(ticketId: string, integrationId: string, done: boolean): Observable<void> {
		return this.apiClientSystems.markIntegrationAsDone(ticketId, integrationId, done).pipe(
			tap(() => this.logger.info(this.loggerName, `${ticketId} ${integrationId}: ${done}`)),
		);
	}

    updateDataTypesInSystem(systemId: string, extendedDataTypes: ExtendedDataType[], action: 'remove' | 'add'): Observable<UpdateSystemCategories> {
        return this.apiClientSystems.updateSystemDataTypes(systemId, extendedDataTypes).pipe(
            tap((res: UpdateSystemCategories) => {
                if (res.result === EditResult.Success) {
                    this.updateSuggestedDataTypesInSystem(systemId, extendedDataTypes, action);
                }
            })
        );
    }

    batchUpdateDataTypesInSystem(batchDataTypes: BatchDataType[]) {
        batchDataTypes.forEach(({ systemId, dataTypes }) => {
            this.systemsStore.update(systemId, (entity) => {
                let extendedDataTypes = entity?.extendedDataTypes || [];
    
                dataTypes.forEach(dataType => {
                    const index = extendedDataTypes.findIndex(edt => edt.id === dataType.id);
    
                    if (index > -1) {
                        // If the data type already exists and its state is 'Suggested', update the state to 'Accepted'
                        if (extendedDataTypes[index].state === SuggestedStateEnum.Suggested) {
                            extendedDataTypes[index].state = SuggestedStateEnum.Accepted;
                        }
                    } else {
                        // If the data type doesn't exist, add it with the 'Accepted' state
                        extendedDataTypes = extendedDataTypes.concat({ ...dataType, state: SuggestedStateEnum.Accepted });
                    }
                });
    
                // Update the entity with the modified extendedDataTypes
                entity.extendedDataTypes = extendedDataTypes;
            });
        });
    }
    
    batchUpdateSystemsDataTypes(batchDataTypes: BatchDataType[]): Observable<void> {
        return this.apiClientSystems.batchUpdateSystemsDataTypes(batchDataTypes).pipe(
            tap(() => { 
                this.batchUpdateDataTypesInSystem(batchDataTypes);
            }),
        )
    }

    private updateSuggestedDataTypesInSystem(systemId: string, extendedDataTypes: ExtendedDataType[], action: 'remove' | 'add'): void {
        this.systemsStore.update(systemId, (entity) => {
            if (action === 'add') {
                const newDataTypes = extendedDataTypes.filter(t => !entity.extendedDataTypes.map(ex => ex.id).includes(t.id));
                for (let type of entity.extendedDataTypes?.filter(t => t.state === SuggestedStateEnum.Suggested)) {
                    if (extendedDataTypes.map(t => t.id).includes(type.id)) {
                        type.state = SuggestedStateEnum.Accepted;
                    }
                }
                entity.extendedDataTypes = entity.extendedDataTypes.concat(...newDataTypes);
            }
            else if (action === 'remove') {
                entity.extendedDataTypes = entity.extendedDataTypes
                    .filter(t => extendedDataTypes.map(e => e.id).includes(t.id) || t.state === SuggestedStateEnum.Suggested);
            }
        });
    }

    updateDataTypeState(systemId: string, dataTypeIds: string[], state: SuggestedStateEnum): Observable<void> {
        const data = [];
        dataTypeIds.forEach(id => data.push({ dataTypeName: id, newState: state } as DataTypeSuggetionsUpdateData));
        return this.apiClientSystems.updateDataTypesState(systemId, data).pipe(
            tap(() => {
                this.systemsStore.update(systemId, (entity) => {
                    dataTypeIds.forEach(id => {
                        const index = entity?.extendedDataTypes?.findIndex(dataType => dataType.id === id);
                        if (index !== -1) {
                            entity.extendedDataTypes[index].state = state;
                        }
                    });
                })
            })
        );
    }

    updateProcessingActivityState(systemId: string, activities: ProcessingActivityPartial[], state: SuggestedStateEnum): Observable<void> {
        const data = [];
        activities.forEach(activity => data.push({ activityId: activity.path, newState: state } as ProcessingActiviySuggetionsUpdateData));

        return this.apiClientSystems.updateProcessingActivitiesState(systemId, data, state).pipe(
            tap(() => {
              this.systemsStore.update(systemId, (entity) => {
                activities.forEach(newActivity => { 
                    const processingActivity = entity?.processActivities?.find(activity => 
                        activity.name?.toLowerCase() === newActivity.name?.toLowerCase());

                        if (processingActivity) {
                            processingActivity.state = state;
                        }
                })

            })
          })  
        );
    }

    moveToInventory(systemIds: string[]): Observable<void> {
        return this.apiClientSystems.moveToInventory(systemIds).pipe(
            switchMap(() => this.getSystemsFromServer()),
        );
    }

    currentAuthenticationApiKey(integrationId: string, integrationType: string): Observable<ApiKeyResponse> {
		return this.apiClientSystems.currentAuthenticationApiKey(integrationId, integrationType);
	}

    getSystemFeedback(systemId: string): Observable<Feedback[]> {
        return this.apiClientFeedback.getSystemFeedbacks(systemId).pipe(
            map(res => res.feedbackData)
        );
    }


    askFeedback(systemIds: string[], employeeId: string, feedbackTypes: FeedbackTypeEnum[]): Observable<Feedback[]> {
        const body: FeedbackRequest = {
            feedbackTypes,
            systemIds,
            assignedReviewerId: employeeId
        }

        return this.apiClientFeedback.requestFeedback(body).pipe(
            tap(() => this.systemsStore.update(systemIds, {generalFeedbackState: FeedbackStateEnum.Pending})),
            map(res => res.feedbackData),
            catchError(error => {
                this.logger.error(this.loggerName, `error: ${error.message}`);
                return EMPTY;
            })
        );
    }

    updateFeedbackState(systemId: string, feedbackId: string, state: FeedbackStateEnum): Observable<void> {
        return this.apiClientFeedback.agentUpdateFeedbackState(feedbackId, state).pipe(
            tap(() => this.systemsStore.update(systemId, {generalFeedbackState: FeedbackStateEnum.None})),
        );
    }

    isFeedbackActive(feedbackState: FeedbackStateEnum): boolean {
        return feedbackState === FeedbackStateEnum.Pending || feedbackState === FeedbackStateEnum.Responded;
    }

    //V2: support also custom integrations actions
    isPreviewV2(id: string, enabledActions?: EnabledRequestActions): Observable<boolean> {
        return this.systemsDirectoryQuery.getById(id).pipe(
            map(system => (system?.isPreview || enabledActions?.preview) ?? false)
        );
    }

    isDeleteV2(id: string, enabledActions: EnabledRequestActions): Observable<boolean> {
        return this.systemsDirectoryQuery.getById(id).pipe(
            map(system => (system?.isDelete || enabledActions?.delete) ?? false)
        );
    }

    isCopyV2(id: string, enabledActions: EnabledRequestActions): Observable<boolean> {
        return this.systemsDirectoryQuery.getById(id).pipe(
            map(system => (system?.isCopy || enabledActions?.copy) ?? false)
        );
    }

    isNameInInventory(name: string): boolean {
		return !!this.systemsQuery.getAll()?.find(entity => entity.name?.toLowerCase() === name?.toLowerCase());
    }

    selectPublicSystem(id: string, isPrivate?: boolean): Observable<PublicSystem> {
        return this.systemsDirectoryQuery.getById(id).pipe(
            map(system => ({
                name: system?.displayName,
                serviceId: system?.serviceId,
                systemType: system?.systemType,
                isPrivate: !!isPrivate,
            } as PublicSystem))
        );
    }

    getIntegrationInstancesForProcessing(ticketType?: TicketType): DataSource[] {
        let integrations: DataSource[] = [];
        const systems = this.systemsQuery.getAll();
        for (let system of systems) {
            system.integrationInstances?.forEach(integration => {
                if (integration.enabled) {
                    let dataSource = {
                        icon: system.iconPath,
                        id: integration.integrationId,
                        displayName: this.getInstanceName(system, integration.customName),
                        disabled: this.isIntegrationDisabled(system.systemType, integration, ticketType),
                        automated: integration.instanceType === IntegrationInstanceType.Automated || integration.instanceType === IntegrationInstanceType.CustomIntegration
                    } as DataSource;
                    
                    
                    if (!!integration.workflows?.length) {
                        dataSource.workflows = integration.workflows;
                    }
                    integrations.push(dataSource);
                }
            });
        }
        return integrations;
    }

    private isIntegrationDisabled(systemType: string, integration: IntegrationSystem, ticketType?: TicketType): boolean {
        if (integration.integrationType === IntegrationInstanceType.CustomIntegration) {
            return !this.getCustomIntegrationEnabled(ticketType, integration.enabledActions);
        }

        if (integration.instanceType === IntegrationInstanceType.Workflow || integration.instanceType === IntegrationInstanceType.Manual) {
            return !integration.enabled;
        }

        const systemDirectoryEntity = this.systemsDirectoryQuery.getEntity(systemType);
        if (ticketType === TicketType.GetCopy) {
            return !systemDirectoryEntity?.isCopy || !integration.enabled;
        }
        else if (ticketType === TicketType.Delete) {
            return !systemDirectoryEntity?.isDelete || !integration.enabled;
        }
        else if (integration.setupType === IntegrationType.OAuth && !integration.authenticated) {
            return true;
        }
        else {
            return !integration.enabled;
        }
    }

    private getCustomIntegrationEnabled(ticketType: TicketType, actions: EnabledRequestActions): boolean {
        switch (ticketType) {
            case TicketType.GetCopy:
                return actions.copy;
            case TicketType.Delete:
                return actions.delete;
            default:
                return false;
        }
    }

    getInstanceName(system: SystemInstance, customName: string): string {
        if (system.systemType === this.MineCustomSystemType) {
            return system.name + ((system.name !== customName && !!customName) ? ' - ' + customName : '');
        }
        else {
            return system.name + (customName ? ' - ' + customName ?? '' : '');
        }
    }

    updateAiData(systemId: string, aiData: SystemAiData): void {
        this.systemsStore.update(systemId, {...this.systemsQuery.getEntity(systemId), aiData});
    }

    uploadIcon(file: File, systemId: string): Observable<void> {
        let fileData: IconSignedUrlResponse;

        return this.apiClientSystems.iconSignedUrl(systemId, { fileName: file.name} as IconSignedUrlPayload).pipe(
            filter(response => !!response.filePath),
            tap((response: IconSignedUrlResponse) => fileData = response),
            switchMap(() => this.fileService.upload(file, fileData.url)),
            concatMap(() => this.apiClientSystems.updateIconUrl(systemId, { filePath: fileData.fullFilePath } as UpdateIconUrlPayload)),
            delay(1000),
            map(() => this.systemsStore.update(systemId, { iconPath: fileData.fullFilePath })) 
        );
    }
}
