import { Injectable } from '@angular/core';
import { VendorManagementQuery } from 'src/app/vendor-management/vendor-managment.query';
import { FrameworksService } from 'src/app/data-types/services/frameworks.service';
import { IntegrationSystem } from 'src/app/api/models/systems/systems.interface';
import { DisplayNumberPipe } from 'src/app/shared/pipes/display-number.pipe';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SystemsColumnKeys } from '../../models/systems-column.enum';
import { SystemsService } from '../../state/systems.service';
import { SystemsQuery } from '../../state/systems.query';
import { SystemInstance } from '../../models/systems.interface';
import { ReviewStatus, SuggestedStateEnum } from 'src/app/api/models/systems/systems.enum';
import { DataTypesService } from 'src/app/data-types/services/data-types.service';
import { FeedbackStateEnum } from 'src/app/api/models/feedback/feedback.enum';
import { RiskCalulationPipe } from 'src/app/processing-activities/services/risk-calulation.pipe';
import { ImpactAssessmentService } from 'src/app/impact-assessment/services/impact-assessment.service';
import { CustomFieldsQuery } from 'src/app/company-settings/state/custom-fields/custom-fields.query';
import { FieldInputType } from 'src/app/api/models/company-settings/custom-fields.enum';
import { SystemFieldIdToNamePipe } from "../../pipes/system-field-id-to-name.pipe";
import { DatePipe } from '@angular/common';
import { SpecialSystemType } from '../../models/custom-system-type.enum';

@Injectable()
export class SystemsTableCsvHelper {
  readonly mineCustomSystemType = SpecialSystemType.Custom;

  constructor(
      private contentPipe: ContentPipe,
      private displayNumberPipe: DisplayNumberPipe,
      private riskCalulation: RiskCalulationPipe,
      private frameworksService: FrameworksService,
      private dataTypesService: DataTypesService,
      private systemsService: SystemsService,
      private systemsQuery: SystemsQuery,
      private vendorManagementQuery: VendorManagementQuery,
      private impactAssessmentService: ImpactAssessmentService,
      private customFieldsQuery: CustomFieldsQuery,
      private systemFieldIdToNamePipe: SystemFieldIdToNamePipe,
      private datePipe: DatePipe,
  ) {}

  getValueByKey(instance: IntegrationSystem, key: string): string {
    const system = this.systemsQuery.getEntity(instance.systemId);
    const values: Record<SystemsColumnKeys, string> = {
      businessOwner: system.owner ?? '-',
      category: system.category ?? '-',
      counter: system.connectedAccounts ? `${this.displayNumberPipe.transform(system.connectedAccounts)}` : '-',
      dataSourceOrigin: system.dataSourceOrigin.toString() ?? '-',
      dataTypes: this.getDataTypes(system),
      feedback: this.getFeedback(system),
      frameworks: this.getFrameworks(system),
      instanceType: this.getInstanceType(instance),
      locations: system.serverLocations?.join(', ') ?? '-',
      review: system?.reviewStatus?.status ?? '-',
      ropa: this.getRopa(system),
      system: this.getSystem(instance, system),
      cyberRating: this.getRating(system.systemId),
      riskCalc: this.riskCalulation.transform(system.vendorRisk?.severity, system.vendorRisk?.likelihood) ?? '-',
      mitigations: (system.vendorRisk?.mitigations.length > 0).toString() ?? '-',
      highPotentialRisk: this.getHightPotentialRisk(system),
      functions: system.functions?.length ? system.functions?.map(f => this.systemFieldIdToNamePipe.transform(f.id)).join(', ') : '-',
      reviewDate: system.reviewStatus?.status === ReviewStatus.Approved ? this.datePipe.transform(system.reviewStatus?.lastActionDate, this.getDateFormat(system.reviewStatus?.lastActionDate)) : '-',
    };

    if (!!values[key]) {
      return values[key];
    }

    const customFieldKey = system.customFields?.find(field => field.id === key);
    
    if (!!customFieldKey) {
      const field = this.customFieldsQuery.getEntity(customFieldKey?.id);
      if (field?.inputType === FieldInputType.Select) {
        return customFieldKey.values.optionIds.length > 0 ? customFieldKey.values.optionIds.map(id => field.inputData.inputOptions.find(f => f.id === id).name).join(', ') : '-';
      } else {
        return customFieldKey.values.text ?? '-';
      }
    }

    return customFieldKey?.value ?? '-';
  }

  private getFrameworks(system: SystemInstance): string {
    const extendedDataTypes = system.extendedDataTypes.filter(dataType => dataType.state === SuggestedStateEnum.Accepted);
    const combinedDataTypes = this.dataTypesService.getExtendedDataTypes(extendedDataTypes);  
    return this.frameworksService.getFrameworksChips(combinedDataTypes).map(type => type.text)?.join(', ') ?? '-';
  }

  private getDataTypes(system: SystemInstance): string {
    const extendedDataTypes = system.extendedDataTypes.filter(dataType => dataType.state === SuggestedStateEnum.Accepted);
    return `${this.dataTypesService.getExtendedDataTypes(extendedDataTypes)?.length}` ?? '-';
  }

  private getInstanceType(instance: IntegrationSystem): string {
    return instance.enabled ? instance.instanceType : '-';
  }

  private getRating(id: string): string {
    const risk = this.vendorManagementQuery.getEntity(id);
    return risk?.riskRating ?? '-';
  }
  
  private getHightPotentialRisk(system: SystemInstance): string {
    return this.impactAssessmentService.getHighRiskNum(system).toString() ?? '-';
  }

  private getFeedback(system: SystemInstance): string {
    switch (system.generalFeedbackState) {
      case FeedbackStateEnum.Pending:
        return this.contentPipe.transform('common.pendingBadgeText');

      case FeedbackStateEnum.Responded:
        return this.contentPipe.transform('common.respondedBadgeText');

      default:
        return '-';
    }
  }
  
  private getRopa(system: SystemInstance): string {
    const pas = this.systemsService.getPasBySystem(system.systemId);
    return `${pas?.length}` ?? '-';
  }

  private getSystem(instance: IntegrationSystem, system: SystemInstance): string {
    if (system.systemType === this.mineCustomSystemType) {
      return system.name + ((system.name !== instance.customName && !!instance.customName) ? ' - ' + instance.customName : '')
    }
    return system.name + (instance.customName ? ' - ' + instance.customName ?? '' : '');
  }

  getDateFormat(date: string): string {
		return new Date().getFullYear() === new Date(date).getFullYear() ? 'dd MMMM' : 'dd MMMM yyyy';
	}
}