import { AiAssessmentPageRequest, AiAssessmentPageSection, AssessmentExternalEntity } from "src/app/api/models/ai-assessments/ai-assessments.interface";
import { AiAssessmentEntityTypeEnum } from "src/app/api/models/ai-assessments/ai-assessments.enum";
import { AgentIdToNamePipe } from "src/app/shared/pipes/agent-id-to-name.pipe";
import { DataFlowEdge } from "src/app/api/models/processing-activities/processing-activities.interface";
import { AiAssessmentsFormControl } from "../models/ai-assessments.interface";
import { AiAssessmentsFormControlTypeEnum, AiAssessmentCustomComponentEnum } from '../models/ai-assessments.enum';
import { AiAssessmentExportTemplateInstance, AiAssessmentExportTemplateSection, AiAssessmentEntityMapper, DataFlowDataExport, DataFlowEdgeExport, DataFlowNodeExport } from "../models/ai-assessment-export.interface";
import { DataFlowNodeTypeEnum } from "src/app/api/models/processing-activities/processing-activities.enum";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";
import { EmployeeDataMapping } from "src/app/api/models/data-mapping/data-mapping.interface";

const ENTITY_TYPE_TO_SECTION_TYPE: Partial<Record<AiAssessmentEntityTypeEnum, AiAssessmentCustomComponentEnum>> = {
  [AiAssessmentEntityTypeEnum.BusinessOwner]: AiAssessmentCustomComponentEnum.BusinessOwner,
  [AiAssessmentEntityTypeEnum.ProcessingActivity]: AiAssessmentCustomComponentEnum.BusinessPurpose,
  [AiAssessmentEntityTypeEnum.LinkedAssessment]: AiAssessmentCustomComponentEnum.LinkedAssessment,
  [AiAssessmentEntityTypeEnum.System]: AiAssessmentCustomComponentEnum.DataSources,
  [AiAssessmentEntityTypeEnum.DataSubject]: AiAssessmentCustomComponentEnum.DataSubjects,
  [AiAssessmentEntityTypeEnum.BusinessUnit]: AiAssessmentCustomComponentEnum.BusinessUnit,
  [AiAssessmentEntityTypeEnum.DataRole]: AiAssessmentCustomComponentEnum.DataRole,
  [AiAssessmentEntityTypeEnum.TransferMechanism]: AiAssessmentCustomComponentEnum.TransferMechanism,
  [AiAssessmentEntityTypeEnum.LegalBasis]: AiAssessmentCustomComponentEnum.LegalBasis,
};

const COMPONENTS_IN_MAPPER = Object.values(ENTITY_TYPE_TO_SECTION_TYPE);

export class AiAssessmentsExportHelper {
  public static async buildInstanceForExport(
    page: AiAssessmentPageRequest,
    customValuesQuery: CustomValuesQuery,
    externalEntityMapper: AiAssessmentEntityMapper,
    ownerNames: EmployeeDataMapping[],
    ignoreIncompleteFields: boolean,
    contentPipe: ContentPipe
  ): Promise<AiAssessmentExportTemplateInstance> {
    const {
      name,
      sections,
      lastApprovedAt,
      type,
      externalRelatedEntities,
      relatedAssessmentIds,
      createdDate,
      reviewDate,
      state
    } = page;
    const controls = await this.buildSectionsForExport(
      sections,
      customValuesQuery,
      externalEntityMapper,
      externalRelatedEntities,
      relatedAssessmentIds,
      ignoreIncompleteFields,
      contentPipe,
    );
    const owner = ownerNames.find(({ id }) => id === page.ownerId);
    return {
      name,
      ownerName: owner ? `${owner.firstName} ${owner.lastName}` : contentPipe.transform('pdf-generator.empty'),
      approvedAt: lastApprovedAt ?? null,
      templateType: type ?? null,
      controls,
      createdDate,
      state,
      reviewDate,
    }
  }

  private static parseSectionData(section: AiAssessmentPageSection): { schema: AiAssessmentsFormControl[], data: any } {
    let data = {};
    let schema = {} as AiAssessmentsFormControl;
    try {
      data = JSON.parse(section.data);
    } catch (error) {
      console.error('Error parsing instance data for export.', error);
    }
    try {
      schema = JSON.parse(section.schema);
    } catch (error) {
      console.error('Error parsing instance schema for export.', error);
    }
    return this.selectSchema(schema, data);
  }

  private static selectSchema(schema: AiAssessmentsFormControl | AiAssessmentsFormControl[], dataToSelect: any): { schema: AiAssessmentsFormControl[], data: any } {
    const data = dataToSelect;
    if (schema instanceof Array) {
      return { schema, data };
    }
    if (schema.type === AiAssessmentsFormControlTypeEnum.Group) {
      return { schema: schema.controls, data };
    }
    return { schema: [schema], data };
  }

  private static async buildSectionsForExport(
    sections: AiAssessmentPageSection[],
    customValuesQuery: CustomValuesQuery,
    externalEntityMapper: AiAssessmentEntityMapper,
    externalRelatedEntities: AssessmentExternalEntity[] = [],
    relatedAssessmentIds: string[] = [],
    ignoreIncompleteFields: boolean = false,
    contentPipe: ContentPipe,
  ): Promise<AiAssessmentExportTemplateSection[]> {
    const sectionsWithControls = sections.map(async section => {
      const { name, label } = section;
      const { schema, data } = this.parseSectionData(section);
      const controls = await this.iterateControls(
        schema,
        data,
        customValuesQuery,
        externalEntityMapper,
        externalRelatedEntities,
        relatedAssessmentIds,
        ignoreIncompleteFields,
        contentPipe,
      );
      return { type: AiAssessmentsFormControlTypeEnum.Group, name, label, controls };
    });
    return Promise.all(sectionsWithControls);
  }

  private static async iterateControls(
    controls: AiAssessmentsFormControl[],
    data: any,
    customValuesQuery: CustomValuesQuery,
    externalEntityMapper: AiAssessmentEntityMapper,
    externalRelatedEntities: AssessmentExternalEntity[],
    relatedAssessmentIds: string[],
    ignoreIncompleteFields: boolean,
    contentPipe: ContentPipe,
  ): Promise<AiAssessmentExportTemplateSection[]> {
    if (!controls || controls?.length === 0) {
      return [];
    }
    const sectionPromises = controls.map(async (control) => {
      if (control.type === AiAssessmentsFormControlTypeEnum.CustomComponent) {
        const section = await this.buildCustomSectionData(
          control,
          data,
          customValuesQuery,
          externalEntityMapper,
          externalRelatedEntities,
          relatedAssessmentIds,
          contentPipe,
        );
        return section;
      } else {
        return this.buildControlData(control, data);
      }
    });
    const sections = await Promise.all(sectionPromises);
    if (!ignoreIncompleteFields) {
      return sections;
    }
    return sections.filter(section => !this.isSectionEmpty(section));
  }

  private static buildControlData(control: AiAssessmentsFormControl, data: any): AiAssessmentExportTemplateSection {
    const { type, name, label } = control;
    if (control.type === AiAssessmentsFormControlTypeEnum.Dropdown && typeof data[name] === 'string') {
      return { type, name, label, value: { value: data[name]} };
    }
    if (!data[name]) {
      return { type, name, label };
    }
    return { type, name, label, value: data[name] };
  }

  private static async buildCustomSectionData(
    control: AiAssessmentExportTemplateSection,
    data: any,
    customValuesQuery: CustomValuesQuery,
    externalEntityMapper: AiAssessmentEntityMapper,
    externalRelatedEntities: AssessmentExternalEntity[],
    relatedAssessmentIds: string[],
    contentPipe: ContentPipe,
  ): Promise<AiAssessmentExportTemplateSection> {
    const { type, name, label } = control;
    if (name === AiAssessmentCustomComponentEnum.Risks) {
      const riskData = this.filterRiskData(data);
      return { type, name, label, value: riskData };
    }
    if (name === AiAssessmentCustomComponentEnum.DataFlow) {
      const value = await this.mapDataFlowEntities(data[name], externalRelatedEntities, externalEntityMapper, contentPipe);
      return { type, name, label, value };
    }
    if (name === AiAssessmentCustomComponentEnum.DpiaNeeded) {
      const value = [{ value: contentPipe.transform(data[name] ? 'ropa.dpiaEnable' : 'ropa.dpiaDisable') }]
      return { type, name, label, value };
    }
    if (name === AiAssessmentCustomComponentEnum.DataFlowNeeded) {
      const value = [{ value: contentPipe.transform(data[name] ? 'ropa.dataFlowEnable' : 'ropa.dataFlowDisable') }]
      return { type, name, label, value };
    }
    if (name === AiAssessmentCustomComponentEnum.PartiesWithAccess) {
      const value = [];
      if (data[name]?.type) {
        value.push({ value: data[name]?.type });
      }
      if (data[name]?.description) {
        value.push({ value: data[name]?.description });
      }
      return { type, name, label, value };
    }
    if (COMPONENTS_IN_MAPPER.includes(name as AiAssessmentCustomComponentEnum)) {
      const assessmentIds = name === AiAssessmentCustomComponentEnum.LinkedAssessment ?relatedAssessmentIds : [];
      const externalEntities = this.filterExternalEntitiesByCustomSection(externalRelatedEntities, name);
      const collectedCustomExternalEntities = this.collectCustomExternalEntitiesByCustomSection(externalRelatedEntities, name, customValuesQuery);
      const listItems = await externalEntityMapper([...externalEntities, ...collectedCustomExternalEntities], assessmentIds);
      return { type, name, label, value: listItems };
    }
    return this.buildControlData(control, data);
  }

  public static filterExternalEntitiesByCustomSection(externalRelatedEntities: AssessmentExternalEntity[], customSectionType: string): AssessmentExternalEntity[] {
    return externalRelatedEntities.filter(system => ENTITY_TYPE_TO_SECTION_TYPE[system.type] === customSectionType) ?? [];
  }

  public static collectCustomExternalEntitiesByCustomSection(externalRelatedEntities: AssessmentExternalEntity[], customSectionType: string, customValuesQuery: CustomValuesQuery): AssessmentExternalEntity[] {
    const entities = [] as AssessmentExternalEntity[];
    externalRelatedEntities.filter(entity => !entity.isDefault).forEach(entity => {
      if (entity.type === AiAssessmentEntityTypeEnum.CustomValue) {
        const customValueEntity = customValuesQuery.getEntity(entity.externalRelatedEntityId);
        if (!!customValueEntity && ENTITY_TYPE_TO_SECTION_TYPE[AiAssessmentEntityTypeEnum[customValueEntity.type]] === customSectionType) {
          entities.push({
            isDefault: false,
            externalRelatedEntityId: entity.externalRelatedEntityId,
            type: AiAssessmentEntityTypeEnum[customValueEntity.type],
          } as AssessmentExternalEntity);
        }
      }
    });
    return entities;
  }

  private static async mapDataFlowEntities(
    data: DataFlowEdge[],
    externalRelatedEntities: AssessmentExternalEntity[],
    externalEntityMapper: AiAssessmentEntityMapper,
    contentPipe: ContentPipe,
  ): Promise<DataFlowDataExport> {
    if (!data) {
      return {
        nodes: [],
        edges: [],
      };
    }
    const systemEntities = externalRelatedEntities.filter(({ type }) => type === AiAssessmentEntityTypeEnum.System);
    const systems = await externalEntityMapper(systemEntities, []);
    const filteredData = data?.filter(edge => edge.source && edge.destination);

    const nodes = systems.map(system => ({
      id: system.value,
      label: system.value,
      dimension: {
        width: 80,
        height: 100,
      },
      meta: {
        type: DataFlowNodeTypeEnum.System,
        serverLocations: system?.serverLocations?.length ? system.serverLocations : [contentPipe.transform('ropa.noLocationSet')],
        iconPath: system?.iconPath
      }
    }));

    const edges: DataFlowEdgeExport[] = [];
    filteredData.forEach(edge => {
      const source = systems.find(({ additional }) => additional === edge.source.id);
      const target = systems.find(({ additional }) => additional === edge.destination.id);

      if (!source || !target || source.value === target.value) {
        return;
      }
      
      edges.push({
        id: `${source.value}-${target.value}`,
        source: source.value,
        target: target.value,
      });
    });

    return {
      nodes: Object.values(nodes),
      edges,
    }
  }

  private static isSectionEmpty({ value }: AiAssessmentExportTemplateSection) {
    const isEmptyString = value === '';
    const isEmtpyArray = value instanceof Array && value.length === 0;
    const isEmptyObject = typeof value === 'object' && Object.keys(value).length === 0;
    const isEmptyDataFlow = value?.nodes && value.nodes.length === 0;

    return !value ||
      isEmptyString ||
      isEmtpyArray ||
      isEmptyObject ||
      isEmptyDataFlow;
  }

  private static filterRiskData(data: any) {
    const riskData = typeof data?.risks === 'object' ? data.risks : data;
    if (typeof data !== 'object' || data instanceof Array) {
      return {};
    }

    const validRiskItems: Record<string, any> = {};
    Object.keys(riskData).forEach(key => {
      if (typeof riskData[key] !== 'object') {
        return;
      }
      validRiskItems[key] = riskData[key];
    }); 
    return validRiskItems;
  }
}