import { computed, Injectable, signal, Signal } from '@angular/core';
import { filter, map, tap, Observable, switchMap, combineLatest, firstValueFrom, from } from 'rxjs';
import { RxActions } from '@rx-angular/state/actions';
import { DatePipe } from '@angular/common';
 
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { IsDateInPastPipe } from "../../shared/pipes/is-date-in-past.pipe";
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { SystemInstance } from 'src/app/systems/models/systems.interface';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { CamelCaseWithSpacesPipe } from 'src/app/shared/pipes/camel-case-with-spaces.pipe';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';
import { AgentIdToNamePipe } from 'src/app/shared/pipes/agent-id-to-name.pipe';

import { AiAssessmentsStore } from '../state/ai-assessments.store';
import { ApiClientAiAssessmentsService } from 'src/app/api/api-client-ai-assessments.service';
import { AiAssessmentEntityTypeEnum } from 'src/app/api/models/ai-assessments/ai-assessments.enum';
import { AiAssessmentActions, AiAssessmentActionUpdateState } from '../models/ai-assessment-actions.interface';
import { AiAssessmentExportMetadata, AiAssessmentExportTemplateInstance, AiAssessmentSectionListItem } from '../models/ai-assessment-export.interface';
import { AiAssessmentEntity, AiAssessmentInstance, AiAssessmentPageRequest, AssessmentExternalEntity, AssessmentTemplateRequest, AssessmentTemplateResponse } from 'src/app/api/models/ai-assessments/ai-assessments.interface';
import { AiAssessmentsFormControl, AiAssessmentsMineOSTemplate, AiAssessmentTableStatus } from '../models/ai-assessments.interface';
import { AiAssessmentsColumnEnum, AiAssessmentsFilteringGroupEnum, AiAssessmentsFormControlTypeEnum, AiAssessmentStatusEnum, AiAssessmentTemplateEnum } from '../models/ai-assessments.enum'; 
import { AiAssessmentsV2ParserService } from './ai-assessments-v2-parser.service';
import { AiAssessmentsExportHelper } from './ai-assessments-export-helper';
import { ProcessingActivitiesQuery } from 'src/app/processing-activities/state/processing-activities.query';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { EmployeesService } from 'src/app/employees/state/employees.service';
import { CustomFieldsQuery } from 'src/app/company-settings/state/custom-fields/custom-fields.query';
import { ProcessingActivityPartial } from 'src/app/api/models/processing-activities/processing-activities.interface';
import { CompanySettingsQuery } from 'src/app/company-settings/state/company-settings.query';
import { PdfGeneratorService } from 'src/app/pdf-generator/pdf-generator.service';
import { ExportTypeEnum } from 'src/app/pdf-generator/pdf-generator.interface';
import { ContentSchemaHelper } from 'src/app/services/content/content-schema-helper';
import { SpecialSystemType } from 'src/app/systems/models/custom-system-type.enum';
import { ProfileQuery } from 'src/app/profile/state/profile.query';

type StatefulConditionFunction = (
  control: AiAssessmentsFormControl,
  state: any
) => [boolean, any];
​
@Injectable({
     providedIn: 'root'
})
export class AiAssessmentsService {

    readonly AI_IMPACT_ASSESSMENT_TEMPLATE = {
        type: 'AI',
        name: 'AI Assessment',
    };
    
    private readonly FORM_TEMPLATES = this.contentPipe.transform('ai-assessments.templates') as AiAssessmentsMineOSTemplate[];

    private styleElement: HTMLStyleElement; // Store a reference to the added style element (in case selected template comes with style)

    constructor(
        private store: AiAssessmentsStore,
        private systemsQuery: SystemsQuery,
        private featureFlagQuery: FeatureFlagQuery,
        private processingActivitiesQuery: ProcessingActivitiesQuery,
        private employeesService: EmployeesService,
        private customValuesQuery: CustomValuesQuery,
        private customFieldsQuery: CustomFieldsQuery,
        private companySettingsQuery: CompanySettingsQuery,
        private apiClientAiAssessmentsService: ApiClientAiAssessmentsService,
        private aiAssessmentsV2ParserService: AiAssessmentsV2ParserService,
        private pdfGeneratorService: PdfGeneratorService,
        private camelCaseWithSpacesPipe: CamelCaseWithSpacesPipe,
        private agentIdToNamePipe: AgentIdToNamePipe,
        private isDateInPastPipe: IsDateInPastPipe,
        private htmlDecodePipe: HtmlDecodePipe,
        private contentPipe: ContentPipe,
        private datePipe: DatePipe,
        private profileQuery: ProfileQuery,
    ) { }

    getEmpty(): Signal<boolean | undefined> {
        return this.store.signal('empty');
    }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }
        
    setDisabled(disabled: boolean): void {
        this.store.set({ 'disabled': disabled });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }
        
    selectLoading(): Observable<boolean> {
        return this.store.select('loading');
    }
        
    getDisabled(): Signal<boolean | undefined> {
        return this.store.signal('disabled');
    }
        
    selectDisabled(): Observable<boolean> {
        return this.store.select('disabled');
    }

    getAssessments(asArray: boolean = false): Signal<Map<string, AiAssessmentInstance> | AiAssessmentInstance[]> {
        if (asArray) {
            return signal(Array.from(new Set([...this.store.get('assessments').values()])));
        }
        return this.store.signal('assessments');
    }

    getActivitiesBySystemId(systemId: string): Signal<ProcessingActivityPartial[]> {
        return computed(() => {
            const paAssessments = this.getPaAssessments()();
      
            return paAssessments
              .filter(assessment => 
                assessment.relatedEntities?.some(entity => 
                  entity.type === AiAssessmentEntityTypeEnum.System && entity.id === systemId
                )
              )
              .map(assessment => this.mapPaAssessmentToProcessingActivity(assessment));
        });
    }

    getPaAssessments(): Signal<AiAssessmentInstance[]> {
        return computed(() => {
            const paAssessments: AiAssessmentInstance[] = [];
            const assessments = this.store.signal('assessments')();
            assessments?.forEach(assessment => {
                if (assessment.templateType === AiAssessmentTemplateEnum.PA) {
                    paAssessments.push(assessment);
                }
            });
            return paAssessments;
        });
    }

    getPaAssessmentsAsProcessingActivities(): Signal<ProcessingActivityPartial[]> {
        return computed(() => {
            return this.getPaAssessments()().map(assessment => 
                this.mapPaAssessmentToProcessingActivity(assessment))
        });
    }

    selectSystemsWithPaAssessmentsMap(): Signal<Map<string, ProcessingActivityPartial[]>> {
        return computed(() => { 
            const processingActivities = this.getPaAssessments()();
            return this.getSystemsWithPaAssessmentsMap(processingActivities);
        })
    }

    private getSystemsWithPaAssessmentsMap(paAssessments: AiAssessmentInstance[]): Map<string, ProcessingActivityPartial[]> {
        const systemsWithPa = new Map<string, ProcessingActivityPartial[]>();

        paAssessments?.forEach(paAssessment => {
            const relatedSystems = paAssessment.relatedEntities
                ?.filter(entity => entity.type === AiAssessmentEntityTypeEnum.System);
            if (relatedSystems.length) { 
                const pa: ProcessingActivityPartial = 
                    this.mapPaAssessmentToProcessingActivity(paAssessment);

                relatedSystems.forEach(s => {
                    const tempPaArray = systemsWithPa.get(s.id);
                    if (!!tempPaArray) {
                        const newArray = [...tempPaArray, paAssessment];
                        systemsWithPa.set(s.id, <ProcessingActivityPartial[]>newArray);
                    }
                    else {
                        systemsWithPa.set(s.id, <ProcessingActivityPartial[]>[pa]);
                    }
                })
            }
        })

        return systemsWithPa;
    }

    private mapPaAssessmentToProcessingActivity(paAssessment: AiAssessmentInstance): ProcessingActivityPartial {
        const systems = paAssessment.relatedEntities
            ?.filter(re => re.type === AiAssessmentEntityTypeEnum.System)
            ?.reduce((acc, curr) => {
               return  acc[curr.id] = {
                ...curr
               }
            }, {})

        const dataSubjectCategories = paAssessment.relatedEntities
            .filter(re => re.type === AiAssessmentEntityTypeEnum.DataSubject).map(dataSubject => dataSubject.id)
        
        return {
            id: paAssessment.id,
            name: paAssessment.name,
            dataSubjectCategories,
            systems
        }
    }
        
    selectAssessments(asArray: boolean = false): Observable<Map<string, AiAssessmentInstance> | AiAssessmentInstance[]> {
        if (asArray) {
            return this.store.select('assessments').pipe(
                map(assessments => Array.from(new Set([...assessments.values()])))
            );
        }
        return this.store.select('assessments');
    }

    selectAssessmentById(id: string): Promise<AiAssessmentInstance> {
        return firstValueFrom(this.selectAssessments().pipe(
            map((assessmentMap: Map<string, AiAssessmentInstance>) => assessmentMap.get(id)),
        ));
    }

    getAssessmentsBySystem(): Signal<Map<string, AiAssessmentInstance[]>> {
        return computed(() => {
            const map = new Map<string, AiAssessmentInstance[]>();
            const assessments = this.store.signal('assessments')();
            assessments?.forEach(assessment => {
                const systems = assessment.relatedEntities?.filter(entity => entity.type === AiAssessmentEntityTypeEnum.System);
                systems?.forEach(system => {
                    if (map.has(system.id)) {
                        map.set(system.id, map.get(system.id).concat(assessment));
                    }
                    else {
                        map.set(system.id, [assessment]);
                    }
                });
            });
            return map;
        });
    }
        
    selectCountByStatus(status?: AiAssessmentStatusEnum): Observable<number> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => {
                switch (status) {
                    case AiAssessmentStatusEnum.Draft:
                        return assessments.filter(assessment => assessment.state === AiAssessmentStatusEnum.Draft).length;
                    case AiAssessmentStatusEnum.Completed:
                        return assessments.filter(assessment => assessment.state === AiAssessmentStatusEnum.Completed).length;
                    default:
                        return assessments.length;
                }
            })
        );
    }

    selectCountByType(type: string): Observable<number> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => assessments.filter(assessment => assessment.templateType === type)?.length ?? 0)
        );
    }

    selectCountByReviewDateIsDue(): Observable<number> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => assessments.filter(assessment => this.isDateInPastPipe.transform(assessment.reviewDate)).length)
        )
    }
        
    selectUniqueDataSources(): Observable<SystemInstance[]> {
        return this.systemsQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => combineLatest([this.systemsQuery.selectAllSystems(), this.selectAssessments(true)])),
            map(([systems, assessments]) => this.getUniqueDataSourcesList(systems, assessments as AiAssessmentInstance[])),
        );
    }
        
    private getUniqueDataSourcesList(systems: SystemInstance[], assessments: AiAssessmentInstance[]): SystemInstance[] {
        return assessments.reduce((prev, curr) => {
            const instances = curr.relatedEntities?.filter(entity => entity.type === AiAssessmentEntityTypeEnum.System)?.map(entity => systems.find(system => system.systemId === entity.id)).filter(Boolean);
            return !!instances ? Array.from(new Set([...prev, ...instances])) : prev;
        }, []);
    }

    selectUniqueDataSubjects(): Observable<AiAssessmentEntity[]> {
        return this.customValuesQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectAssessments(true)),
            map(assessments => this.getUniqueEntityList(AiAssessmentEntityTypeEnum.DataSubject, assessments as AiAssessmentInstance[])),
        );
    }

    selectUniqueBusinessUnits(): Observable<AiAssessmentEntity[]> {
        return this.customValuesQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectAssessments(true)),
            map(assessments => this.getUniqueEntityList(AiAssessmentEntityTypeEnum.BusinessUnit, assessments as AiAssessmentInstance[])),
        );
    }

    selectUniqueBusinessOwners(): Observable<AiAssessmentEntity[]> {
        return this.customValuesQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectAssessments(true)),
            map(assessments => this.getUniqueEntityList(AiAssessmentEntityTypeEnum.BusinessOwner, assessments as AiAssessmentInstance[])),
        );
    }

    private getUniqueEntityList(valueType: AiAssessmentEntityTypeEnum, assessments: AiAssessmentInstance[]): AiAssessmentEntity[] {
        const entityMap = new Map<string, AiAssessmentEntity>();
    
        assessments.forEach(assessment => {
            const instances = assessment.relatedEntities
                ?.filter(entity => entity.type === valueType)
                .filter(Boolean);
    
            instances?.forEach(entity => {
                if (!entityMap.has(entity.id)) {
                    entityMap.set(entity.id, entity);
                }
            });
        });
    
        return Array.from(entityMap.values());
    }

    searchAssessments(assessments: AiAssessmentInstance[], search: string): AiAssessmentInstance[] {
        if (!search) return assessments;
        return assessments.filter(assessment => assessment.name?.toLowerCase()?.includes(search?.toLowerCase()));
    }
    
    filterAssessments(assessments: AiAssessmentInstance[], filters: FilterCategory[]): AiAssessmentInstance[] {
        if (!filters) return assessments;
        for (let filter of filters) {
            if (filter.options.some(option => option.selected)) {
                if (filter.id === AiAssessmentsFilteringGroupEnum.Type) {
                    assessments = this.filterAssessmentsByProperty(assessments, filter, 'templateType');
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.Status) {
                    assessments = this.filterAssessmentsByProperty(assessments, filter, 'state');
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.DataSource) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.System);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.ReviewDate) {
                    assessments = (assessments as AiAssessmentInstance[]).filter(assessment => this.isDateInPastPipe.transform(assessment.reviewDate));
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.DataSubject) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.DataSubject);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.BusinessOwner) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.BusinessOwner);
                }
                if (this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentTemplateSavedQuestions) && !!this.customFieldsQuery.getEntity(filter.id)) {
                    assessments = this.filterAssessmentsByCustomField(assessments, filter);
                }
            }
        }
        return assessments;
    }

    private filterAssessmentsByProperty<T extends keyof AiAssessmentInstance>(assessments: AiAssessmentInstance[], filter: FilterCategory, property: T): AiAssessmentInstance[] {
        return assessments.filter(
            assessment => filter.options
                .filter(option => option.selected)
                .map(option => option.id)
                .includes(assessment[property as string])
        );
    }
    
    private filterAssessmentsByEntityType(assessments: AiAssessmentInstance[], filter: FilterCategory, type: AiAssessmentEntityTypeEnum): AiAssessmentInstance[] {
        return (assessments as AiAssessmentInstance[]).filter(
              assessment => filter.options
                .filter(option => option.selected)
                .map(option => option.id)
                .some(item => assessment.relatedEntities.filter(e => e.type === type)?.map(e => e.id)?.includes(item))
            );
    }

    private filterAssessmentsByCustomField(assessments: AiAssessmentInstance[], filter: FilterCategory): AiAssessmentInstance[] {
        return (assessments as AiAssessmentInstance[]).filter(
              assessment => filter.options
                .filter(option => option.selected)
                .map(option => option.id)
                .some(item => this.aiAssessmentsV2ParserService.getInstanceCustomFieldValues(assessment)?.get(filter.id)?.map(value => value.value)?.includes(item))
            );
    }
    
    sortAssessments(assessments: AiAssessmentInstance[], sort: MineSort): AiAssessmentInstance[] {
        switch (sort.active) {
            case AiAssessmentsColumnEnum.Name:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    a.name?.localeCompare(b.name) : b.name?.localeCompare(a.name)
                );
        
            case AiAssessmentsColumnEnum.Type:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    a.templateType?.localeCompare(b.templateType) : b.templateType?.localeCompare(a.templateType)
                );
        
            case AiAssessmentsColumnEnum.Status:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    this.sortByStatus(a, b) : this.sortByStatus(b, a)
                );
        
            case AiAssessmentsColumnEnum.ReviewDate:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    this.sortByReviewDate(a, b) : this.sortByReviewDate(b, a)
                );
    
          default:
            return assessments;
        }
    }
    
    private sortByStatus(a: AiAssessmentInstance, b: AiAssessmentInstance): number {
        const aStatus = this.getAssessmentTableStatus(a);
        const bStatus = this.getAssessmentTableStatus(b);

        return `${aStatus.status.value}-${new Date(a.lastModified ?? a.createdAt).getTime()}`
            .localeCompare(`${bStatus.status.value}-${new Date(b.lastModified ?? b.createdAt).getTime()}`);
    }

    private getAssessmentTableStatus(instance: AiAssessmentInstance): AiAssessmentTableStatus {
        const statuses = this.contentPipe.transform('ai-assessments.statuses') as DropdownOption[];

        return {
            date: this.datePipe.transform(instance.lastModified ?? instance.createdAt, 'MMM y'),
            status: statuses.find(status => status.id === instance.state)
        } as AiAssessmentTableStatus;
    }

    private sortByReviewDate(a: AiAssessmentInstance, b: AiAssessmentInstance): number {
        return new Date(a.reviewDate || '0').valueOf() - new Date(b.reviewDate || '0').valueOf();
    }

    getMineOSTemplates(): AiAssessmentsMineOSTemplate[] {
        return this.FORM_TEMPLATES;
    }

    getMineOSTemplateByType(type: AiAssessmentTemplateEnum): AiAssessmentsMineOSTemplate {
        return this.FORM_TEMPLATES.find(template => template.type === type);
    }
        
    getMineOSTemplate(type: string, name: string): AiAssessmentsMineOSTemplate {
        return this.FORM_TEMPLATES.find(template => template.name === name && template.type === type); 
    }
    
    getTemplates(): Signal<Map<string, AssessmentTemplateResponse>> {
        return this.store.signal('templates');
    }
    
    getTemplate(type: AiAssessmentTemplateEnum, name: string): AssessmentTemplateResponse {
        return Array.from(this.store.get('templates').values()).find(template => template.type === type && name === template.name);
    }

    getTemplateInstance(type: AiAssessmentTemplateEnum, name: string): AiAssessmentsMineOSTemplate | AssessmentTemplateResponse {
        return this.getMineOSTemplate(type, name) ?? this.getTemplate(type, name);
    }

    selectTemplates(): Observable<Map<string, AssessmentTemplateResponse>> {
        return this.store.select('templates');
    }

    selectTemplate(templateId: string): Observable<AssessmentTemplateResponse> {
        return this.selectTemplates().pipe(
            map(templates => templates.get(templateId))
        );
    }

    selectAssessmentTypes(): Observable<Array<string>> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => Array.from(new Set(assessments.map(assessment => assessment.templateType))))
        );
    }

    // Function to append style to the DOM
    appendTemplateStyleToDOM(style: string): void {
        this.styleElement = document.createElement("style");
        this.styleElement.innerText = style;
        document.body.appendChild(this.styleElement);
    }

    // Function to remove style from the DOM
    removeTemplateStyleFromDOM(): void {
        if (this.styleElement && this.styleElement.parentNode) {
            this.styleElement.parentNode.removeChild(this.styleElement);
        }
    }

    getActions(): RxActions<AiAssessmentActions, {}> {
        return this.store.actions;
    }

    jumpToFormSection(name: string): void {
        this.store.actions.jumpToFormSection(name);
    }

    submitFormRequest(request: AiAssessmentInstance): void {
        this.store.actions.submitFormRequest(request);
    }
        
    updateInstanceName(instance: AiAssessmentInstance, newName: string): void {
        this.store.actions.updateInstanceName({instance, newName});
    }

    updateInstanceState(state: AiAssessmentActionUpdateState): void {
      this.store.actions.updateInstanceState(state);
    }

    updateInstanceReviewDate(instanceId: string, reviewDate?: string, ownerId?: string): void {
        this.store.actions.updateInstanceReviewDate({instanceId, reviewDate, ownerId});
    }

    deleteFormRequest(id: string): void {
        this.store.actions.deleteInstance(id);
    }

    selectInstance(instanceId: string): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.getInstanceById(instanceId).pipe(
            map(instance => this.getDecodedInstance(instance)),
            tap(instance => this.updateInstanceInStore(instance))
        );
    }

    updateInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        // MIGRATION TO CUSTOM TEMPLATES
        if (this.featureFlagQuery.getFlag(FeatureFlags.DevAIAssessmentsCustomTemplates) && !request.templateSchema) {
            const aiTemplate = this.AI_IMPACT_ASSESSMENT_TEMPLATE;
            request = {
                ...request,
                templateSchema: JSON.stringify(this.getMineOSTemplate(aiTemplate.type, aiTemplate.name).template)
            };
        }

        return this.apiClientAiAssessmentsService.updateInstance(request).pipe(
            tap(() => this.updateInstanceInStore(request)),
            map(() => request),
        );
    }

    updateAssessmentReviewDate(instanceId: string, reviewDate?: string, ownerId?: string): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.updateInstanceReviewDate(instanceId, reviewDate, ownerId).pipe(
            switchMap(() => this.apiClientAiAssessmentsService.getInstanceById(instanceId)),
            tap(instance => this.updateInstanceInStore(instance))
        );
    }
        
    createInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.createInstance(request).pipe(
            tap(instance => this.updateInstanceInStore(instance ?? request)),
            map(instance => instance ?? request)
        );
    }
        
    deleteInstance(id: string): Observable<void> {
        return this.apiClientAiAssessmentsService.deleteInstance(id).pipe(
            tap(() => this.removeInstanceFromStore(id))
        );
    }

    private getDecodedInstance(instance: AiAssessmentInstance): AiAssessmentInstance {
        if (instance?.data) {
            return { 
                ...instance, 
                name: this.htmlDecodePipe.transform(instance.name),
                data: this.htmlDecodePipe.transform(instance.data) 
            } as AiAssessmentInstance;
        }
        return instance;
    }
        
    private updateInstanceInStore(instance: AiAssessmentInstance): void {
        const assessments = this.store.get('assessments');
        assessments.set(instance.id, instance);
        this.store.set('assessments', state => new Map(assessments.entries()))
    }
        
    private removeInstanceFromStore(id: string): void {
        this.store.get('assessments').delete(id);
    }

    buildSchemaBySections(sections: AiAssessmentsFormControl[]): string {
        const schemaArr = [];
        if (!sections?.length) return JSON.stringify(schemaArr);
        sections.forEach(sec => {
            const section = this.sectionNameMapper(sec);
            schemaArr.push({
                ...section,
                controls: sec.controls?.map(ctrl => this.controlNameMapper(ctrl)),
                type: section.type === AiAssessmentsFormControlTypeEnum.NewSection ? AiAssessmentsFormControlTypeEnum.Group : section.type
            });
        });
        return JSON.stringify(schemaArr);
    }

    private sectionNameMapper(section: AiAssessmentsFormControl): AiAssessmentsFormControl {
        const updatedSectionName = this.camelCaseWithSpacesPipe.transform(section.label);
        if (section.type !== AiAssessmentsFormControlTypeEnum.CustomComponent) {
            return {...section, name: updatedSectionName} as AiAssessmentsFormControl;
        }
        return section;
    }

    private controlNameMapper(control: AiAssessmentsFormControl): AiAssessmentsFormControl {
        if (control.type !== AiAssessmentsFormControlTypeEnum.CustomComponent) {
            const updatedControlName = this.camelCaseWithSpacesPipe.transform(control.label);
            return {...control, name: updatedControlName} as AiAssessmentsFormControl;
        }
        return control;
    }

    createTemplate(request: AssessmentTemplateRequest): Observable<AssessmentTemplateResponse> {
        return this.apiClientAiAssessmentsService.createTemplate(request).pipe(
            tap(template => this.updateTemplateInStore(template)),
        );
    }

    updateTemplate(request: AssessmentTemplateResponse): Observable<void> {
        return this.apiClientAiAssessmentsService.updateTemplate(request).pipe(
            tap(() => this.updateTemplateInStore(request)),
        );
    }

    deleteTemplate(templateId: string): Observable<void> {
        return this.apiClientAiAssessmentsService.deleteTemplate(templateId).pipe(
            tap(() => this.removeTemplateFromStore(templateId))
        );
    }

    private updateTemplateInStore(template: AssessmentTemplateResponse): void {
        const templates = this.store.get('templates');
        templates.set(template.id, template);
        this.store.set('templates', state => new Map(templates.entries()))
    }

    private removeTemplateFromStore(id: string): void {
        this.store.get('templates').delete(id);
    }

    getUniqueControlNameSet(controls: Array<AiAssessmentsFormControl>): Set<string> {
        const set = new Set<string>();
        for (let control of controls) {
          set.add(control.label?.toLowerCase());
        }
        return set;
    }

    generateNewSection(name?: string): AiAssessmentsFormControl {
        const defaultSectionLabel = this.contentPipe.transform('ai-assessments.defaultSectionLabel');

        return {
            type: AiAssessmentsFormControlTypeEnum.NewSection,
            name: !!name ? this.camelCaseWithSpacesPipe.transform(name) : defaultSectionLabel,
            label: name ?? defaultSectionLabel,
            controls: []
        } as AiAssessmentsFormControl;
    }

    validateControlLabel(control: AiAssessmentsFormControl): boolean {
        return !control.label?.trim();
    }

    isNewSectionIsEmpty(section: AiAssessmentsFormControl): boolean {
        if (section.type === AiAssessmentsFormControlTypeEnum.NewSection) {
            return !section.controls?.length;
        }
        return false;
    }

    isGroupSectionIsEmpty(section: AiAssessmentsFormControl): boolean {
        if (section.type === AiAssessmentsFormControlTypeEnum.Group) {
            return !section.controls?.length;
        }
        return false;
    }

    hasDropdownsWithoutOptions(sections: AiAssessmentsFormControl[]): boolean {
        const condition: StatefulConditionFunction = (control, state) => {
            return [!control.options?.length, state];
        };
        return this.validateSections(sections, condition, null, AiAssessmentsFormControlTypeEnum.Dropdown);
    }

    hasDropdownsWithEmptyOption(sections: AiAssessmentsFormControl[]): boolean {
        const condition: StatefulConditionFunction = (control, state) => {
            return [control.options.some(option => !option.value?.trim()), state];
        };
        return this.validateSections(sections, condition, null, AiAssessmentsFormControlTypeEnum.Dropdown);
    }

    hasMultipleCustomComponentInstances(sections: AiAssessmentsFormControl[]): boolean {
        const condition: StatefulConditionFunction = (control, state) => {
            if (!state) state = {};
            if (state[control.name]) {
                return [true, state];
            } else {
                state[control.name] = true;
                return [false, state];
            }
        };
        return this.validateSections(sections, condition, {}, AiAssessmentsFormControlTypeEnum.CustomComponent);
    }

    private validateSections(sections: AiAssessmentsFormControl[], condition: StatefulConditionFunction, initialState: any, type: AiAssessmentsFormControlTypeEnum): boolean {
        let state = initialState;
        for (let section of sections) {
            if (section.controls?.length) {
                for (let control of section.controls) {
                    if (control.type === type) {
                        const [result, newState] = condition(control, state);
                        if (result) {
                            window.scrollTo({
                                top: document.documentElement.scrollHeight,
                                behavior: 'smooth'
                            });
                            return true;
                        }
                        state = newState;
                    }
                }
            }
        }
        return false;
    }

    async getBusinessOwnerData(externalRelatedEntities?: AssessmentExternalEntity[]): Promise<EmployeeDataMapping[]> {
        const employeeIds = externalRelatedEntities
            ?.filter(({ type }) => type === AiAssessmentEntityTypeEnum.BusinessOwner)
            .map(({ externalRelatedEntityId }) => externalRelatedEntityId);
        return employeeIds?.length ? await firstValueFrom(this.employeesService.getEmployeesByIds(employeeIds)) : [];
    }

    async collectExternalEntitiesData(externalRelatedEntities: AssessmentExternalEntity[] = [], relatedAssessmentIds: string[] = [], hideCustomSystemIds?: boolean): Promise<AiAssessmentSectionListItem[]> {
        const employees = await this.getBusinessOwnerData(externalRelatedEntities);
        relatedAssessmentIds.forEach(async asessmentId => {
            const assessment = await this.selectAssessmentById(asessmentId);
            if (!assessment) {
                return;
            }
            
            const approvedAt = assessment.approvedAt ? ` - ${this.contentPipe.transform('ai-assessments.approvedOn', {
                params: {
                    date: this.datePipe.transform(assessment.approvedAt, 'dd/MM/yyyy')
             }
            })}` : '';
            
            return listItems.push({
                value: `${assessment.name}${approvedAt}`,
                additional: assessment.templateType,
            });
        });

        const listItems: AiAssessmentSectionListItem[] = [];
        externalRelatedEntities?.forEach(async ({ type, externalRelatedEntityId }) => {
            switch (type) {
                case AiAssessmentEntityTypeEnum.BusinessOwner:
                    const employee = employees.find(({ id }) => id === externalRelatedEntityId);
                    if (!employee) {
                        return;
                    }
                    return listItems.push({
                        value: `${employee.firstName} ${employee.lastName}`,
                    });
                
                case AiAssessmentEntityTypeEnum.ProcessingActivity:
                    const pa = this.processingActivitiesQuery.getEntity(externalRelatedEntityId);
                    if (!pa) {
                        return;
                    }
                    return listItems.push({
                        value: pa.name,
                    });
                
                case AiAssessmentEntityTypeEnum.System:
                    const system = this.systemsQuery.getEntity(externalRelatedEntityId);
                    if (!system) {
                        return;
                    }
                    const additional = 
                        hideCustomSystemIds && (Object.values(SpecialSystemType) as string[]).includes(system.systemType)
                            ? ''
                            : system.systemId;
                    return listItems.push({
                        value: system.name,
                        iconPath: system.iconPath,
                        additional,
                        serverLocations: system.serverLocations
                    });
                    
                default:
                    const customValueEntity = this.customValuesQuery.getEntity(externalRelatedEntityId);
                    if (!!customValueEntity) {
                        return listItems.push({
                            value: customValueEntity.name
                        });
                    }

                    return listItems.push({
                        value: externalRelatedEntityId,
                    });
            }
        });
        return listItems;
    }
    
    replaceTemplateType(assessment: AiAssessmentPageRequest): AiAssessmentPageRequest {
        const { type } = assessment;
        const cmsTypes = this.contentPipe.transform('ai-assessments.types');
        return { ...assessment, type: cmsTypes[type] ?? cmsTypes[AiAssessmentTemplateEnum.Empty] }
    }

    async buildInstancesForExport(assessments: AiAssessmentInstance[], ignoreIncompleteFields: boolean): Promise<AiAssessmentExportTemplateInstance[]> {
        const ownerIds = assessments.map(({ ownerId }) => ownerId).filter(Boolean);
        const ownerNames = ownerIds.length > 0 ? await firstValueFrom(this.employeesService.getEmployeesByIds(ownerIds)) : [];
        const parsedAssessments = assessments.map(assessment => this.aiAssessmentsV2ParserService.parseInstanceToPageRequestV2(assessment));
        return Promise.all(parsedAssessments.map(instance => AiAssessmentsExportHelper.buildInstanceForExport(
            this.replaceTemplateType(instance),
            this.customValuesQuery,
            this.collectExternalEntitiesData.bind(this),
            ownerNames,
            ignoreIncompleteFields,
            this.contentPipe,
        )));
    }

    exportInstances(assessments: AiAssessmentInstance[], metaData: Partial<AiAssessmentExportMetadata>): Observable<ArrayBuffer> {
        const instances$ = from(this.buildInstancesForExport(assessments, metaData.ignoreIncompleteFields));
        const ropaDetails$ = this.companySettingsQuery.selectRopaDetails();
        const fullName$ = this.profileQuery.selectFullname();

        return combineLatest([instances$, ropaDetails$, fullName$]).pipe(
            switchMap(([instances, ropaDetails, agentName]) => this.pdfGeneratorService.sendRequest({
              exportType: ExportTypeEnum.Assessment,
              squidexContents: [ContentSchemaHelper.PdfGenerator],
              data: {
                instances,
                metaData: {
                    ...metaData,
                    ropaDetails,
                    agentName,
                },
              }
            }))
        );
    }
}