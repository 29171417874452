import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { SystemInstance } from '../models/systems.interface';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';

import { produce } from 'immer';
import { SpecialSystemType } from '../models/custom-system-type.enum';

export interface SystemsState extends EntityState<SystemInstance> { }
@Injectable({ 
	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'systems',
	idKey: 'systemId',
	producerFn: produce
})
export class SystemsStore extends EntityStore<SystemsState> {

	private readonly MineCustomSystemType = SpecialSystemType.Custom;

	constructor (
		private titleCasePipe: TitleCasePipe,
		private htmlDecodePipe: HtmlDecodePipe
	) {
		super();
	}

	akitaPreAddEntity(system: SystemInstance): SystemInstance {
		return this.getDecodedSystemInstance(system);
	}

	private getDecodedSystemInstance(systemInstance: SystemInstance): SystemInstance {
		systemInstance.systemType

		return {
			...systemInstance,
			name: this.htmlDecodePipe.transform(this.getSystemName(systemInstance?.name, systemInstance?.systemType)),
			description: this.htmlDecodePipe.transform(systemInstance?.description),
			customFields: systemInstance?.customFields?.map(field => ({...field, value: this.htmlDecodePipe.transform(field.value)})),
			disclaimerValue: this.htmlDecodePipe.transform(systemInstance?.disclaimerValue),
			serverLocations: systemInstance?.serverLocations?.map(location => this.htmlDecodePipe.transform(location)),
		}
	}

	private getSystemName(name: string, type: string): string {
		return type === this.MineCustomSystemType ? name : this.titleCasePipe.transform(name);
	}
}