import { Injectable } from "@angular/core";
import { combineLatest, first, map, Observable, switchMap, filter } from "rxjs";

import { HttpClientHelperService } from "./http-client-helper.service";
import {
    AiAssessmentInstance,
    AiAssessmentPage, AssessmentTemplatePageResponse,
    AssessmentTemplateRequest,
    AssessmentTemplateResponse
} from "./models/ai-assessments/ai-assessments.interface";
import { FeatureFlags } from "./models/profile/profile-feature-flags.enum";
import { FeatureFlagQuery } from "../feature-flag/state/feature-flag.query";
import { AiAssessmentsV2ParserService } from "../ai-assessments/services/ai-assessments-v2-parser.service";
import { CustomFieldsQuery } from "../company-settings/state/custom-fields/custom-fields.query";
import { CustomValuesQuery } from "../company-settings/state/custom-values/custom-values.query";

@Injectable({
  	providedIn: 'root'
})
export class ApiClientAiAssessmentsService {
	private instancesController = 'assessments/v1/instances';
    private readonly instancesControllerV2 = 'assessment/v2/assessment-page';
	private readonly templatesController = 'assessments/v1/templates';
    private readonly templatesControllerV2 = 'assessment/v2/template-page';

    private assessmentsApiV2FF: boolean;
    
    private assessmentReviewReminderV2FF: boolean;

    getInstances: () => Observable<AiAssessmentInstance[]>; // This is a function that returns an Observable of AiAssessmentInstance[]
    getInstanceById: (id: string) => Observable<AiAssessmentInstance>;
    updateInstance: (request: AiAssessmentInstance) => Observable<void>;
    createInstance: (request: AiAssessmentInstance) => Observable<AiAssessmentInstance>;
    createTemplate: (request: AssessmentTemplateRequest) => Observable<AssessmentTemplateResponse>;
    updateTemplate: (request: AssessmentTemplateRequest) => Observable<void>;
    getTemplates: () => Observable<AssessmentTemplateResponse[]>;
    deleteInstance: (id: string) => Observable<void>;
    deleteTemplate: (id: string) => Observable<void>;
    updateInstanceReviewDate: (instanceId: string, reviewDate?: string, ownerId?: string) => Observable<void>;

    constructor(
        private httpClientHelper: HttpClientHelperService,
        private featureFlagQuery: FeatureFlagQuery,
        private customFieldsQuery: CustomFieldsQuery,
        private customValuesQuery: CustomValuesQuery,
        private aiAssessmentsV2ParserService: AiAssessmentsV2ParserService
	) {
        this.initFeatureFlag();
        this.initMethods();
    }

    private initFeatureFlag(): void {
        const flags = this.featureFlagQuery.getMultipleFlags([
            FeatureFlags.DevAssessmentsApiV2,
            FeatureFlags.AssessmentReviewReminderV2
        ]);
        this.assessmentsApiV2FF = flags.devAssessmentsApiV2;
        this.assessmentReviewReminderV2FF = flags.assessmentReviewReminderV2;
    }

    private initMethods(): void {
        if (this.assessmentsApiV2FF) {
            this.getInstances = this.getInstancesV2;
            this.getInstanceById = this.getInstanceByIdV2;
            this.updateInstance = this.updateInstanceV2;
            this.createInstance = this.createInstanceV2;
            this.createTemplate = this.createTemplateV2;
            this.updateTemplate = this.updateTemplateV2;
            this.getTemplates = this.getTemplatesV2;
            this.deleteInstance = this.deleteInstanceV2;
            this.deleteTemplate = this.deleteTemplateV2;
            this.updateInstanceReviewDate = this.assessmentReviewReminderV2FF ? this.updateInstanceReviewDateV2 : this.updateInstanceReviewDateV1;
        }
         else {
            this.getInstances = this.getInstancesV1;
            this.getInstanceById = this.getInstanceByIdV1;
            this.updateInstance = this.updateInstanceV1;
            this.createInstance = this.createInstanceV1;
            this.createTemplate = this.createTemplateV1;
            this.updateTemplate = this.updateTemplateV1;
            this.getTemplates = this.getTemplatesV1;
            this.deleteInstance = this.deleteInstanceV1;
            this.deleteTemplate = this.deleteTemplateV1;
            this.updateInstanceReviewDate = this.updateInstanceReviewDateV1;
        }
    }

    getInstancesV1(): Observable<AiAssessmentInstance[]> {
        return this.httpClientHelper.invokeGetAuth<AiAssessmentInstance[]>(`${this.instancesController}`);
    }

    getInstancesV2(): Observable<AiAssessmentInstance[]> {
        return combineLatest([
            this.customFieldsQuery.selectLoading(),
            this.customValuesQuery.selectLoading(),
        ]).pipe(
            map(loaders => loaders.every(loader => !loader)),
            filter(loaded => loaded),
            switchMap(() => this.httpClientHelper.invokeGetAuth<AiAssessmentPage[]>(`${this.instancesControllerV2}`)),
            first(),
            map((pages: AiAssessmentPage[]) => pages.map(page => this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page)))
        );
    }

    getInstanceByIdV1(id: string): Observable<AiAssessmentInstance> {
        return this.httpClientHelper.invokeGetAuth<AiAssessmentInstance>(`${this.instancesController}/${id}`);
    }

    getInstanceByIdV2(id: string): Observable<AiAssessmentInstance> {
        return this.httpClientHelper.invokeGetAuth<AiAssessmentPage>(`${this.instancesControllerV2}/${id}`)
            .pipe(
                map((page: AiAssessmentPage) => this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page))
            );
    }

    updateInstanceV1(request: AiAssessmentInstance): Observable<void> {
        return this.httpClientHelper.invokePatchAuth<AiAssessmentInstance>(`${this.instancesController}/${request.id}`, request).pipe(
            map(() => void 0)
        );
    }

    updateInstanceV2(request: AiAssessmentInstance): Observable<void> {
        return this.httpClientHelper.invokePutAuth<AiAssessmentPage>(`${this.instancesControllerV2}/${request.id}`, this.aiAssessmentsV2ParserService.parseInstanceToPageRequestV2(request))
            .pipe(
                map(() => void 0)
            );
    }

    updateInstanceReviewDateV1(instanceId: string, reviewDate?: string, ownerId?: string): Observable<void> {
        return this.httpClientHelper.invokePostAuth<void>(`${this.instancesController}/${instanceId}/set-review`, {reviewDate, ownerId}).pipe(
            map(() => void 0)
        );
    }

    updateInstanceReviewDateV2(instanceId: string, reviewDate?: string, ownerId?: string): Observable<void> {
        return this.httpClientHelper.invokePostAuth<void>(`${this.instancesControllerV2}/${instanceId}/set-review`, {reviewDate, ownerId, setNoFutureReminders: !reviewDate}).pipe(
            map(() => void 0)
        );
    }

    createInstanceV1(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        return this.httpClientHelper.invokePostAuth<AiAssessmentInstance>(`${this.instancesController}`, request);
    }

    createInstanceV2(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        request.createdAt = new Date().toISOString();
        return this.httpClientHelper.invokePostAuth<AiAssessmentPage>(`${this.instancesControllerV2}`, this.aiAssessmentsV2ParserService.parseInstanceToPageRequestV2(request, true))
            .pipe(
                map(page => this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page))
            );
    }

    deleteInstanceV1(id: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth<void>(`${this.instancesController}/${id}`);
    }

    deleteInstanceV2(id: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth<void>(`${this.instancesControllerV2}/${id}`);
    }

    getTemplatesV1(): Observable<AssessmentTemplateResponse[]> {
        return this.httpClientHelper.invokeGetAuth<AssessmentTemplateResponse[]>(`${this.templatesController}`);
    }

    getTemplatesV2(): Observable<AssessmentTemplateResponse[]> {
        return this.httpClientHelper.invokeGetAuth<AssessmentTemplatePageResponse[]>(`${this.templatesControllerV2}`)
            .pipe(
                map(templatePages => templatePages.map(templatePage => this.aiAssessmentsV2ParserService.parseTemplatePageToResponse(templatePage)))
            );
    }

	updateTemplateV1(request: AssessmentTemplateResponse): Observable<void> {
		return this.httpClientHelper.invokePatchAuth<AssessmentTemplateResponse>(`${this.templatesController}/${request.id}`, request).pipe(
            map(() => void 0)
        );
	}

    updateTemplateV2(request: AssessmentTemplateResponse): Observable<void> {
		return this.httpClientHelper.invokePutAuth<void>(`${this.templatesControllerV2}/${request.id}`, this.aiAssessmentsV2ParserService.parseTemplateRequestToPageV2(request)).pipe(
            map(() => void 0)
        );
	}

    createTemplateV1(request: AssessmentTemplateRequest): Observable<AssessmentTemplateResponse> {
        return this.httpClientHelper.invokePostAuth<AssessmentTemplateResponse>(`${this.templatesController}`, request);
    }

    createTemplateV2(request: AssessmentTemplateRequest): Observable<AssessmentTemplateResponse> {
        return this.httpClientHelper.invokePostAuth<AssessmentTemplatePageResponse>(`${this.templatesControllerV2}`, this.aiAssessmentsV2ParserService.parseTemplateRequestToPageV2(request))
            .pipe(
                map(templatePage => this.aiAssessmentsV2ParserService.parseTemplatePageToResponse(templatePage))
            );
    }

    deleteTemplateV1(id: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth<void>(`${this.templatesController}/${id}`);
    }

    deleteTemplateV2(id: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth<void>(`${this.templatesControllerV2}/${id}`);
    }
}