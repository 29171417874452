import { Injectable } from '@angular/core';
import { combineQueries } from '@datorama/akita';
import { first, map, Observable, tap } from 'rxjs';

import { DataTypesTableItem } from 'src/app/data-types/data-types-table/data-types-table.interface';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SystemInstance } from '../../systems/models/systems.interface';
import { SystemsQuery } from '../../systems/state/systems.query';
import { DataType } from '../models/data-types.interface';
import { ConvertPiiTypeToDataTypePipe } from '../../shared/pipes/convert-pii-type-to-data-type.pipe';
import { ExtendedDataType } from 'src/app/api/models/systems/systems.interface';
import { DeepDiveResponse } from 'src/app/api/models/data-types/data-types.interface';
import { ApiClientDataTypesService } from 'src/app/api/api-client-data-types.service';
import { DataTypeSource, SuggestedStateEnum } from 'src/app/api/models/systems/systems.enum';
import { PII_TYPE_TO_DATA_TYPE_ID_MAP } from 'src/app/shared/models/pii-to-data-type-map';
import { LoggerService } from 'src/app/logger/logger.service';
import { CustomDataType } from 'src/app/api/models/company-settings/custom-data-types.interface';
import { DataTypesStore } from '../state/data-types.store';
import { DataTypesQuery } from '../state/data-types.query';
import { CustomDataTypesService } from 'src/app/company-settings/state/custom-data-types.service';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';

@Injectable({
  providedIn: 'root'
})
export class DataTypesService {

  private readonly loggerName = 'DataTypesService';
  
  constructor(
    private contentPipe: ContentPipe,
    private systemsQuery: SystemsQuery,
    private convertPiiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe,
    private apiDataTypesClient: ApiClientDataTypesService,
    private logger: LoggerService,
    private dataTypesStore: DataTypesStore,
    private dataTypesQuery: DataTypesQuery,
    private customDataTypesService: CustomDataTypesService,
  ) { }

  // data types store is combination of pre-defined data types that retrived from squidex 
	// and custom data types from company settings api 
	init(): Observable<void> {
		return this.customDataTypesService.loadCustomDataTypes().pipe(
			first(),
			tap(res => this.setDataTypesStore(res)),
			tap(() => this.dataTypesStore.setLoading(false)),
			map(() => void 0),
		);
	}

  private setDataTypesStore(customDataTypes: CustomDataType[]): void {
		const cmsDataTypes = this.contentPipe.transform('data-types.dataTypes').map(f => ({...f, isCustom: false})) as DataType[];
		this.dataTypesStore.set([...cmsDataTypes, ...customDataTypes]);
	}

  getDataTypes(dataTypeIds?: string[]): DataType[] {
    const uniqueDataTypesSet = new Set<string>(dataTypeIds);
    const allDataTypes = this.dataTypesQuery.getAll();
    return dataTypeIds ? allDataTypes.filter(d => uniqueDataTypesSet.has(d.id)) : allDataTypes;
  }

  selectDataTypesTableData(includeMissingTypes = true): Observable<Map<string, DataTypesTableItem>> {
    const apisToRetreiveTableData: Observable<any>[] = [
      this.systemsQuery.selectAllSystemsWithDataTypes(),
      this.systemsQuery.selectSystemsWithoutDataTypes(),
    ];

    return combineQueries(apisToRetreiveTableData).pipe(
      map(res => this.initDataTypesBySystems(res[0], res[1], includeMissingTypes)),
    );
  }

  getAcceptedDataTypes(systemInstances: SystemInstance[]): DataType[] {
    const acceptedDataTypesSet = new Set<string>();
    systemInstances.forEach(system => {
      system.extendedDataTypes?.forEach(dataType => {
         if (dataType.state === SuggestedStateEnum.Accepted) { 
          acceptedDataTypesSet.add(dataType.id);
         }
      });
    });

    return this.getDataTypes(Array.from(acceptedDataTypesSet));
  }

  private initDataTypesBySystems(systemsWithDataTypes: SystemInstance[], systemsWithoutDataTypes: SystemInstance[], includeMissingTypes: boolean): Map<string, DataTypesTableItem> {
    const tableData = new Map<string, DataTypesTableItem>();
    const allDataTypes = new Map<string, DataType>();
    this.getDataTypes().forEach(d => allDataTypes.set(d.id, d));

    systemsWithDataTypes.forEach(s => {
      const dataTypesOfSystem = this.getSystemAcceptedDataTypes(s); // not a unique array
      dataTypesOfSystem.forEach(d => {
        const dataTypeId = d.source === DataTypeSource.PIIDIscovered ?
              this.convertPiiTypeToDataTypePipe.transform(d.id) : d.id;
        if (tableData.has(dataTypeId)) {
          if (!tableData.get(dataTypeId).systems.find(system => system.systemId === s.systemId)) {
            tableData.get(dataTypeId).systems.push(s); // add to systems array only if system is not exist
          }
          if (d.numOfRecords !== undefined) {
            tableData.get(dataTypeId).records += d.numOfRecords;
          }
        } else {
          tableData.set(dataTypeId, { dataType: allDataTypes.get(dataTypeId), systems: [s], records: d.numOfRecords ?? 0 });
        }
      });
    });
    
    if (includeMissingTypes && systemsWithoutDataTypes.length > 0) {
      const missing = this.contentPipe.transform('data-types.missingTypes');
      tableData.set(this.contentPipe.transform('data-types.missingTypes'), { dataType: { id: missing, name: missing, }, systems: systemsWithoutDataTypes }); // add systems with missing type
    }
    return tableData;
  }

  getSystemAcceptedDataTypes(system: SystemInstance): ExtendedDataType[] {
    return system.extendedDataTypes.filter(type => type.state === SuggestedStateEnum.Accepted);
  }

  getPiiTypeFromDataType(dataType: string): string[] {
    const piiTypes = [];
  
    Array.from(PII_TYPE_TO_DATA_TYPE_ID_MAP.entries()).forEach(value => {
      if (value[1] === dataType) {
        piiTypes.push(value[0]);
      }
    });

    return piiTypes;
  }

  // loop thorugh "extendedDataTypes" and remove duplications
  // return data types as cms representation
  getExtendedDataTypes(extendedDataTypes: ExtendedDataType[]): DataType[] {
    const dataTypeIdsSet = new Set<string>();
    extendedDataTypes.forEach(d => {
      const dataTypeId = d.source === DataTypeSource.PIIDIscovered ? this.convertPiiTypeToDataTypePipe.transform(d.id) : d.id;
      dataTypeIdsSet.add(dataTypeId);
    });
    return this.getDataTypes(Array.from(dataTypeIdsSet));
  }

  getDeepDiveData(systemId: string, dataTypes: string): Observable<DeepDiveResponse> {
    return this.apiDataTypesClient.getDeepDiveData(systemId, dataTypes);
  }

  getSuggestedDataTypes(dataTypes: ExtendedDataType[]): DataType[] {
    const extendedDataTypes = dataTypes?.filter(dataType => dataType.state === SuggestedStateEnum.Suggested);
    const hasPiiDiscoveryRecords = dataTypes?.some(dataType => dataType.state === SuggestedStateEnum.Accepted && dataType.source === DataTypeSource.PIIDIscovered && dataType.numOfRecords > 0);
    return !hasPiiDiscoveryRecords ? this.getExtendedDataTypes(extendedDataTypes) : undefined;
  }

  getDataTypesDropdownOptionsGroupedByCategory(dataTypes: DataType[], excludedDataTypeIds: string[] = []): DropdownOption[] {
    const options = dataTypes
        .filter(dataType => !excludedDataTypeIds.includes(dataType.id))
        .reduce((acc, curr) => {
            const categoryIndex = acc.findIndex(item => item.value === curr.category);

            const option = {
                id: curr.id,
                value: curr.name
            };

            if (categoryIndex === -1) {
                // Create a new category if it doesn't exist
                acc.push({
                    id: `category-${curr.category}`,
                    value: curr.category,
                    options: [option]
                });
            } else {
                // Add to the existing category
                acc[categoryIndex].options.push(option);
            }

            return acc;
        }, []);

    return options.sort((a, b) => a.value.localeCompare(b.value)) as DropdownOption[];
  }
}
